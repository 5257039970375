import React from "react";

import { Button } from "common/components/ui/Button";
import { DialogClose } from "common/components/ui/Dialog";
import { DrawerClose } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import SuccessImage from "assets/images/success.png";

export default function Success() {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <>
      <img
        src={SuccessImage}
        alt=""
        className="mx-auto my-4 w-[276px] lg:mt-8 lg:w-[364px]"
      />
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Seat(s) assigned successfully!
      </h3>
      <p className="mb-8 text-center text-black-500">
        The assigned lifetime seats are now available on the selected workspace{" "}
      </p>
      <Close asChild>
        <Button variant="secondary-black" size="lg" className="lg:ml-auto">
          Continue
        </Button>
      </Close>
    </>
  );
}
