import React from "react";
import clsx from "clsx";

import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";

import { Conversation } from "../../types";
import MessageDetails from "./MessageDetails";
import TimestampDisplay from "./TimestampDisplay";
import ParticipantInfo from "../ParticipantInfo";

interface ConversationItemProps {
  conversation: Conversation;
  onSelect: () => void;
  isLoading: boolean;
  isSelected: boolean;
  isPreviousSelected: boolean;
}

export default function ConversationItem({
  conversation,
  onSelect,
  isLoading,
  isSelected,
  isPreviousSelected, // Use this to determine if the previous item is selected
}: ConversationItemProps) {
  const {
    last_message: lastMessage,
    unread_count: unreadCount,
    owner: accountId,
    participants,
  } = conversation;

  // TODO: {lastMessage.sender.id} should be string once new inbox is release
  const isMine = accountId === String(lastMessage.sender.id);

  return (
    <>
      <button
        type="button"
        className={clsx(
          "my-1 flex flex-col gap-2 rounded-2xl p-4 transition-colors duration-100 ease-linear last:mb-0",
          isSelected && "bg-purple-50",
        )}
        aria-label={`Conversation with ${participants[0].full_name}`}
        disabled={isLoading}
        onClick={onSelect}
      >
        {/* Participant details (name, avatar, occupation) */}
        <ParticipantInfo conversation={conversation} />

        {/* Last message from the conversation */}
        <MessageDetails conversation={conversation} isMine={isMine} />

        {/* Timestamp details */}
        <div className="flex w-full flex-row items-center justify-between gap-8">
          <LinkedInAccountsTag accountIds={[accountId]} />

          <TimestampDisplay
            createdAt={lastMessage.created_at}
            unreadCount={unreadCount}
          />
        </div>
      </button>

      {/* Separator line */}
      <div
        className={clsx(
          "h-px w-full bg-black-100 last:hidden",
          isSelected || isPreviousSelected ? "opacity-0" : "opacity-100",
        )}
      />
    </>
  );
}
