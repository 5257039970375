import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";
import useAppStore from "common/hooks/useAppStore";

import { get, HTTPError, patch } from "../helpers/HTTP";
import { PaymentSource } from "../types";

async function getPrimaryPaymentSource(
  workspaceId: string,
): Promise<PaymentSource> {
  try {
    const { payment_source: paymentSource } = await get(
      `workspaces/${workspaceId}/payment-source`,
    );

    // This is a temporary fix, until the backend resolves the issue with correct caching of payment source, when the card is removed through Chargebee
    return paymentSource.type ? paymentSource : null;
  } catch (err) {
    if (err instanceof HTTPError && err.code === 404) {
      return null;
    }
    throw err;
  }
}
async function setPrimaryPaymentSourceRequest(
  workspaceId: string,
  paymentSourceId: string,
) {
  await patch(`workspaces/${workspaceId}/payment-source`, {
    payment_source_id: paymentSourceId,
  });
}
export default function usePrimaryPaymentSource() {
  const queryClient = useQueryClient();
  const { id: workspaceId, subscription } = useSelectedWorkspaceContext();
  const isPaymentMethodHidden = useAppStore(
    (state) => state.debugOptions.isPaymentMethodHidden,
  );
  const { paymentSourceKeys } = getQueryKeys(workspaceId);

  const {
    data: primaryPaymentSource,
    isLoading: isLoadingPrimaryPaymentSource,
  } = useQuery<PaymentSource>({
    queryKey: paymentSourceKeys.primary(),
    queryFn: () => getPrimaryPaymentSource(workspaceId),
    enabled: !!subscription,
  });
  const {
    mutateAsync: setPrimaryPaymentSource,
    isPending: isSettingPrimaryPaymentSource,
  } = useMutation({
    onMutate: ({ paymentSource }) => {
      const originalPaymentSource: PaymentSource = queryClient.getQueryData(
        paymentSourceKeys.primary(),
      );
      queryClient.setQueryData(paymentSourceKeys.primary(), paymentSource);
      return originalPaymentSource;
    },
    onError: (_, __, originalPaymentSource) => {
      queryClient.setQueryData(
        paymentSourceKeys.primary(),
        // if original payment source is undefined, the query update is ignored. this ensures that it updates
        originalPaymentSource || null,
      );
      toast.error("Error updating payment source");
    },
    mutationFn: ({ paymentSource }: { paymentSource: PaymentSource }) =>
      setPrimaryPaymentSourceRequest(workspaceId, paymentSource.id),
  });

  return {
    primaryPaymentSource: isPaymentMethodHidden ? null : primaryPaymentSource,
    isLoadingPrimaryPaymentSource,
    setPrimaryPaymentSource,
    isSettingPrimaryPaymentSource,
  };
}
