import React from "react";
import { DotOutline } from "@phosphor-icons/react";
import clsx from "clsx";

import { typedObjectEntries } from "common/helpers/utils";
import { Campaign } from "common/types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
} from "common/components/ui/Select";

import { campaignStates } from "../../constants";

interface StateFilterProps {
  selectedFilter: keyof typeof campaignStates;
  setFilter: (filter: keyof typeof campaignStates) => void;
  campaigns: Campaign[];
}

export default function StateFilter({
  selectedFilter,
  setFilter,
  campaigns,
}: StateFilterProps) {
  const { label: stateLabel, iconColor: stateIconColor } =
    campaignStates[selectedFilter] ?? {};

  return (
    <Select value={selectedFilter} onValueChange={setFilter}>
      <SelectTrigger className="w-40" variant="simple">
        {selectedFilter ? (
          <span className="inline-flex items-center gap-1">
            <DotOutline size={20} weight="fill" className={stateIconColor} />
            {stateLabel}
          </span>
        ) : (
          "Filter by"
        )}
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={null}>
          <SelectItemText>
            All
            <span className="text-black-500">
              &nbsp;({campaigns ? campaigns.length : 0})
            </span>
          </SelectItemText>
        </SelectItem>
        {typedObjectEntries(campaignStates).map(
          ([key, { label, iconColor }]) => (
            <SelectItem value={key} key={key}>
              <DotOutline
                size={20}
                weight="fill"
                className={clsx("-ml-3 mr-2", iconColor)}
              />
              <SelectItemText>{label}</SelectItemText>
              <span className="text-black-500">
                &nbsp;(
                {campaigns
                  ? campaigns.filter(({ state }) => state === key).length
                  : 0}
                )
              </span>
            </SelectItem>
          ),
        )}
      </SelectContent>
    </Select>
  );
}
