import React from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  formatPeriodUnitLabel,
  formatPrice,
  formatShortDate,
} from "common/helpers/utils";
import useEstimateUpdate from "common/datahooks/useEstimateUpdate";
import { PlanId } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import useDebounce from "common/hooks/useDebounce";
import useEstimateCreation from "common/datahooks/useEstimateCreation";

interface NewPriceProps {
  licenseCount: number;
  selectedPlan: PlanId;
}

export default function NewPrice({
  licenseCount,
  selectedPlan,
}: NewPriceProps) {
  const { subscription } = useSelectedWorkspaceContext();
  const debouncedLicenseCount = useDebounce(licenseCount);
  const { estimateUpdate, isLoadingEstimateUpdate } = useEstimateUpdate({
    planId: selectedPlan,
    quantity: debouncedLicenseCount,
    prorate: false,
  });
  const { estimateCreation, isLoadingEstimateCreation } = useEstimateCreation({
    planId: selectedPlan,
    quantity: debouncedLicenseCount,
  });

  const isLoading = isLoadingEstimateCreation || isLoadingEstimateUpdate;

  let nextBillingAt;
  let amountDue;
  let priceTitle = "New price";
  if (subscription) {
    if (subscription.status === "cancelled") {
      priceTitle = "Price";
    }

    nextBillingAt = estimateUpdate?.nextBillingAt;
    amountDue = estimateUpdate?.amountDue;
  } else {
    if (selectedPlan === "Basic-USD-Monthly") {
      priceTitle = "Price after trial";
    }

    nextBillingAt = estimateCreation?.nextBillingAt;
    amountDue = estimateCreation?.amountDue;
  }

  let startingDateComponent;
  if (!subscription && selectedPlan === "Basic-USD-Yearly") {
    startingDateComponent = (
      <span className="text-caption-12-bold text-purple-600">
        Starting immediately
      </span>
    );
  } else if (isLoading) {
    startingDateComponent = <Skeleton className="h-[17px] w-40" />;
  } else {
    startingDateComponent = (
      <span className="text-caption-12-bold text-purple-600">
        Starting from {formatShortDate(nextBillingAt * 1000)}
      </span>
    );
  }

  return (
    <div className="flex items-center justify-between px-2 py-1">
      <div className="flex flex-col gap-y-0.5">
        <span className="text-button-14 text-black-600">{priceTitle}</span>
        {startingDateComponent}
      </div>
      {isLoading ? (
        <Skeleton className="h-6 w-14" />
      ) : (
        <span className="text-body-16-bold">
          {formatPrice(amountDue / 100)}/{formatPeriodUnitLabel(selectedPlan)}
        </span>
      )}
    </div>
  );
}
