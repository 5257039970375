import React from "react";

import { formatPrice } from "common/helpers/utils";
import { PlanId } from "common/types";
import useEstimateCreation from "common/datahooks/useEstimateCreation";
import Skeleton from "common/components/ui/Skeleton";
import useEstimateUpdate from "common/datahooks/useEstimateUpdate";
import useDebounce from "common/hooks/useDebounce";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

interface ToPayNowProps {
  licenseCount: number;
  selectedPlan: PlanId;
}

export default function ToPayNow({
  selectedPlan,
  licenseCount,
}: ToPayNowProps) {
  const { subscription, license_count: workspaceLicenseCount } =
    useSelectedWorkspaceContext();
  const debouncedLicenseCount = useDebounce(licenseCount);

  const isDowngrade = licenseCount < workspaceLicenseCount;

  const isPriceZero =
    selectedPlan === "Basic-USD-Monthly" &&
    (!subscription || subscription.status === "in_trial" || isDowngrade);

  const { estimateCreation, isLoadingEstimateCreation } = useEstimateCreation({
    planId: selectedPlan,
    quantity: debouncedLicenseCount,
  });

  const { estimateUpdate, isLoadingEstimateUpdate } = useEstimateUpdate({
    planId: selectedPlan,
    quantity: debouncedLicenseCount,
    prorate: true,
    isDisabled: isPriceZero,
  });

  const isLoading = isLoadingEstimateCreation || isLoadingEstimateUpdate;

  let price = 0;
  if (!isPriceZero && !isLoading) {
    price = estimateCreation
      ? estimateCreation.amountDue
      : estimateUpdate.amountDue;
  }

  return (
    <div className="flex items-center justify-between px-2 py-1">
      <span className="text-body-16-bold">To pay now</span>

      {isLoading && !isPriceZero ? (
        <Skeleton className="h-8 w-24" />
      ) : (
        <span className="text-headline-2xl-bold text-purple-600">
          {formatPrice(price / 100)}
        </span>
      )}
    </div>
  );
}
