import React, { useState } from "react";
import {
  ArrowBendUpRight,
  DotsThreeVertical,
  PencilSimpleLine,
  SignOut,
  Trash,
} from "@phosphor-icons/react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogClose,
} from "common/components/ui/Dialog";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Member } from "common/types";
import useCustomer from "common/datahooks/useCustomer";

import useWorkspaceMembers from "../../datahooks/useWorkspaceMembers";
import ChangeRoleDialog from "./ChangeRoleDialog";

export default function MemberActions({ member }: { member: Member }) {
  const [actionId, setActionId] = useState<"delete" | "revoke">();
  const {
    removeMember,
    leaveWorkspace,
    revokeInvite,
    resendInvite,
    isRemovingMember,
    isLeavingWorkspace,
    isRevokingInvite,
    isResendingInvite,
  } = useWorkspaceMembers({ disableQuery: true });
  const { name: workspaceName } = useSelectedWorkspaceContext();

  const { role, customer_id: memberId, email, token: inviteToken } = member;

  const [isChangingRole, setIsChangingRole] = useState(false);

  const { customerInfo } = useCustomer();
  const { email: customerEmail } = customerInfo;

  function onRevokeInvite(): void {
    revokeInvite({ inviteToken });
  }

  function handleRemoveClick(): void {
    if (customerEmail === email) {
      leaveWorkspace({ memberId });
      return;
    }
    removeMember({ memberId });
  }

  let dropdownContent = memberId ? (
    <>
      <DropdownMenuItem onClick={() => setIsChangingRole(true)}>
        <PencilSimpleLine size={20} />
        Change role
      </DropdownMenuItem>

      <DropdownMenuItem
        className="text-red-400"
        onClick={() => setActionId("delete")}
      >
        <Trash size={20} />
        Remove from workspace
      </DropdownMenuItem>
    </>
  ) : (
    <>
      <DropdownMenuItem
        onClick={() => {
          resendInvite({ inviteToken });
        }}
      >
        <ArrowBendUpRight size={20} />
        Resend Invite
      </DropdownMenuItem>

      <DropdownMenuItem
        className="text-red-400"
        onClick={() => setActionId("revoke")}
      >
        <Trash size={20} />
        Revoke Invite
      </DropdownMenuItem>
    </>
  );

  if (customerEmail === email) {
    dropdownContent = (
      <DropdownMenuItem
        className="text-red-400"
        onClick={() => setActionId("delete")}
      >
        <SignOut size={20} />
        Leave workspace
      </DropdownMenuItem>
    );
  }

  const isLoading =
    isRevokingInvite ||
    isRemovingMember ||
    isResendingInvite ||
    isLeavingWorkspace;

  return (
    <>
      {/* Dropdown menu */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            isLoading={isLoading}
            intent="iconOnly"
            variant="secondary-black"
          >
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent side="left">{dropdownContent}</DropdownMenuContent>
      </DropdownMenu>

      {/* Remove from workspace dialog */}
      <Dialog
        open={actionId === "delete"}
        onOpenChange={(isOpen) => {
          setActionId(isOpen ? "delete" : null);
        }}
      >
        <DialogContent className="max-w-[540px]">
          <div className="mb-4 flex flex-col items-center gap-2">
            <h3 className="text-center text-headline-2xl-bold text-black-950">
              {customerEmail === email
                ? "Are you sure you want to leave this workspace?"
                : "Remove from workspace?"}
            </h3>

            <span className="mb-8 text-center text-black-500">
              {customerEmail === email ? (
                "You will no longer be able to access this workspace or any data related to it"
              ) : (
                <>
                  Are you sure you want to remove this{" "}
                  <span className="text-body-14-bold text-black-900">
                    &apos;{email}&apos;{" "}
                  </span>
                  from {workspaceName}
                </>
              )}
            </span>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Button
              variant="secondary-black"
              disabled={isRemovingMember || isLeavingWorkspace}
              onClick={() => setActionId(null)}
            >
              Cancel
            </Button>

            <DialogClose asChild>
              <Button
                variant="tertiary-danger"
                isLoading={isRemovingMember || isLeavingWorkspace}
                onClick={handleRemoveClick}
              >
                {customerEmail === email ? "Leave workspace" : "Remove"}
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>

      {/* Revoke invite dialog */}
      <Dialog
        open={actionId === "revoke"}
        onOpenChange={(isOpen) => {
          setActionId(isOpen ? "revoke" : null);
        }}
      >
        <DialogContent className="max-w-[540px]">
          <div className="mb-4 flex flex-col items-center gap-2">
            <h3 className="text-headline-2xl-bold text-black-950">
              Revoke invite?
            </h3>

            <span className="mb-8 text-center text-black-500">
              This email will no longer be able to join your workspace
            </span>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Button
              variant="secondary-black"
              disabled={isRevokingInvite}
              onClick={() => setActionId(null)}
            >
              Cancel
            </Button>

            <DialogClose asChild>
              <Button
                variant="tertiary-danger"
                isLoading={isRevokingInvite}
                onClick={onRevokeInvite}
              >
                Revoke invite
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>

      {/* Change role dialog */}
      <ChangeRoleDialog
        isOpen={isChangingRole}
        currentRole={role}
        memberId={memberId}
        onOpenChange={setIsChangingRole}
      />
    </>
  );
}
