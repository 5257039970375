import React from "react";
import { LinkedinLogo } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";

import useThisWasMeChallenge from "../../hooks/useThisWasMeChallenge";

import ArrowTurnLeftUpIcon from "assets/images/thiswasme-arrow-up.svg?react";

interface ThisWasMeChallengeProps {
  email: string;
  onSuccess: () => void;
  onAppChallengeFail: () => void;
}

export default function ThisWasMeChallenge({
  email,
  onSuccess,
  onAppChallengeFail,
}: ThisWasMeChallengeProps) {
  useThisWasMeChallenge(email, onSuccess, onAppChallengeFail);

  return (
    <div className="flex flex-col">
      <div className="relative my-12 flex flex-col rounded-2xl border border-black-200 p-6 md:px-12">
        <div className="mb-6 flex gap-x-2">
          <LinkedinLogo className="size-8" weight="fill" />
          <span className="text-headline-lg-bold">Is this you?</span>
        </div>
        <div className="mb-4 h-4 w-10/12 rounded-full bg-black-200" />
        <div className="mb-6 h-4 w-5/12 rounded-full bg-black-200" />
        <Button variant="primary-black" asChild>
          <span>This was me</span>
        </Button>
        <ArrowTurnLeftUpIcon className="absolute -bottom-10 self-center" />
      </div>
      <span className="text-center text-button-14 text-black-400">
        Check your LinkedIn app
      </span>
    </div>
  );
}
