import React, { JSX } from "react";
import clsx from "clsx";

import { formatTimestamp } from "common/helpers/utils";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import { Lead } from "common/types";

import { ConversationMessage } from "../../../types";
import MessageMenu from "./MessageMenu";

interface MessageBubbleProps {
  isMine: boolean;
  message: ConversationMessage;
  participants: Lead[];
  setEditMessage?: () => void;
  children: JSX.Element;
}

export default function MessageBubble({
  isMine,
  message,
  participants,
  setEditMessage = null,
  children,
}: MessageBubbleProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const {
    created_at: createdAt,
    edited_at: editedAt,
    deleted_at: deletedAt,
    sender,
  } = message;

  const canModify = createdAt >= Date.now() - 1000 * 60 * 60 && isMine;
  const shouldShowMessageMenu = isMine && canModify && !deletedAt;

  const getSenderColor = (senderId: number): string => {
    const groupColors: string[] = [
      "text-red-500",
      "text-blue-500",
      "text-purple-500",
      "text-yellow-500",
      "text-green-500",
      "text-orange-500",
      "text-magenta-500",
    ];

    if (participants?.length > 2) {
      const senderIndex = participants.findIndex(
        (participant) => Number(participant.id) === senderId,
      );
      return groupColors[senderIndex % groupColors.length];
    }

    return null;
  };

  const getBackgroundColor = (): string => {
    if (deletedAt) {
      return "bg-red-50";
    }
    return isMine ? "bg-purple-50" : "bg-white";
  };

  const timestampComponent = isTabletOrDesktop && createdAt && (
    <div
      className={clsx([
        "mb-1 flex flex-row items-center gap-2",
        isMine ? "ml-auto" : "mr-auto",
      ])}
    >
      <span
        className={clsx(
          "text-caption-12-bold",
          !isMine && getSenderColor(sender?.id),
        )}
      >
        {isMine ? "You" : sender?.full_name}
      </span>

      <span className="text-caption-12-regular text-black-500">
        {formatTimestamp(createdAt).toUpperCase()}
        {editedAt ? " (Edited)" : ""}
      </span>
    </div>
  );

  return (
    <div className="flex flex-col justify-end gap-0.5">
      {timestampComponent}

      <div
        className={clsx([
          "flex flex-row items-center gap-2",
          isMine ? "ml-auto rounded-br-none" : "mr-auto rounded-tl-none",
        ])}
      >
        <RenderIf condition={shouldShowMessageMenu}>
          <MessageMenu
            accountId={sender?.id}
            message={message}
            setEditMessage={setEditMessage}
          />
        </RenderIf>

        <div className="flex flex-row gap-2">
          <div
            className={clsx([
              "flex w-fit max-w-[482px] flex-col items-start justify-center rounded-20 p-3.5",
              isMine ? "rounded-tr-none" : "rounded-tl-none",
              getBackgroundColor(),
            ])}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
