/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  CaretRight,
  Check,
  ClockCounterClockwise,
} from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { Tag, TagVariantType } from "common/components/ui/Tag";
import DataTable from "common/components/DataTable";
import { WorkspaceAccount } from "common/types";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import { Dialog, DialogContent } from "common/components/ui/Dialog";

import LicenseTag from "./LicenseTag";
import MobilePreview from "./MobilePreview";
import AccountActions from "./AccountActions";
import useWorkspaceAccountActions from "../hooks/useWorkspaceAccountActions";

function DesktopAccountActionsDialog({
  account,
}: {
  account: WorkspaceAccount;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { action, setAction, actionComponent } = useWorkspaceAccountActions({
    account,
    onClose: () => setIsOpen(false),
    goBack: () => setIsOpen(false),
  });

  useEffect(() => {
    if (action) {
      setIsOpen(true);
    }
  }, [action]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setAction(null), 300);
    }
  }, [isOpen]);

  return (
    <>
      <AccountActions account={account} setAction={setAction} />
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            setIsOpen(false);
          }
        }}
      >
        <DialogContent className="w-[540px]">{actionComponent}</DialogContent>
      </Dialog>
    </>
  );
}

export default function AccountsTable({
  accounts,
}: {
  accounts: WorkspaceAccount[];
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);
  const [previewAccount, setPreviewAccount] = useState<WorkspaceAccount>(null);

  function onRowClick(account: WorkspaceAccount) {
    setIsMobilePreviewOpen(true);
    setPreviewAccount(account);
  }

  const columns = useMemo(() => {
    const templateColumns: ColumnDef<WorkspaceAccount>[] = [
      {
        header: "Account",
        cell: ({
          row: {
            original: {
              picture_url: pictureUrl,
              full_name: fullName,
              occupation,
              license,
            },
          },
        }) => (
          <div className="flex items-center gap-x-2 overflow-hidden md:gap-x-3">
            <ProfileImage
              size={isTabletOrDesktop ? "lg" : "md"}
              src={pictureUrl}
            />
            <div className="flex flex-col gap-y-0.5 overflow-hidden md:gap-y-1">
              <div className="flex items-center gap-x-2">
                <span className="line-clamp-1 text-body-14-bold md:text-headline-lg-bold">
                  {fullName}
                </span>
                <LicenseTag license={license} className="md:hidden" />
              </div>
              <span className="line-clamp-1 text-caption-12-regular text-black-700 md:text-body-14-regular md:text-black-600">
                {occupation}
              </span>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "state",
        header: isTabletOrDesktop ? "Login status" : "Status",
        cell: ({ getValue }) => {
          const isDisconnected = getValue() !== "LoggedIn";

          let tagContent: ReactNode = isDisconnected ? (
            <ClockCounterClockwise />
          ) : (
            <Check />
          );
          let variant: TagVariantType = isDisconnected
            ? "quaternary"
            : "success";

          if (isTabletOrDesktop) {
            tagContent = isDisconnected ? "Not connected" : "Connected";
            variant = isDisconnected ? "disabled" : "success";
          }
          return (
            <Tag variant={variant} className="max-md:p-0">
              {tagContent}
            </Tag>
          );
        },
      },
    ];

    if (isTabletOrDesktop) {
      templateColumns.push({
        header: "Outreach seat",
        cell: ({
          row: {
            original: { license },
          },
        }) => <LicenseTag license={license} />,
      });
      templateColumns.push({
        id: "action",
        cell: ({ row: { original } }) => (
          <DesktopAccountActionsDialog account={original} />
        ),
      });
    } else {
      templateColumns.push({
        id: "action",
        cell: () => (
          <Button intent="labelIcon" size="sm" variant="quaternary-black">
            <CaretRight />
          </Button>
        ),
      });
    }

    return templateColumns;
  }, [isTabletOrDesktop]);

  return (
    <>
      <DataTable
        columns={columns}
        data={accounts}
        onRowClick={!isTabletOrDesktop && onRowClick}
      />
      <RenderIf condition={!isTabletOrDesktop && !!previewAccount}>
        <MobilePreview
          account={previewAccount}
          isOpen={isMobilePreviewOpen}
          onClose={() => setIsMobilePreviewOpen(false)}
        />
      </RenderIf>
    </>
  );
}
