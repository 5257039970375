import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

export default function useValidateAccountLicense(accountId: string) {
  const {
    accounts,
    licenses,
    license_count: licenseCount,
  } = useSelectedWorkspaceContext();

  const account = accounts.find(({ id }) => id === accountId);

  const usedLicenseCount = licenses.filter(
    ({ account_id }) => !!account_id,
  ).length;

  const hasAvailableLicense = licenseCount > usedLicenseCount;

  return hasAvailableLicense && !!account.license;
}
