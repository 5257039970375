import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { toast } from "react-toastify";
import { PlusCircle } from "@phosphor-icons/react";

import { EmailSchema } from "common/schemas";
import { HTTPError } from "common/helpers/HTTP";
import { UserRole } from "common/types";
import { Button } from "common/components/ui/Button";
import RestrictedComponent from "common/components/RestrictedComponent";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Input } from "common/components/ui/Input";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { typedObjectEntries } from "common/helpers/utils";
import { userRoles } from "common/constants";
import Switcher from "common/components/ui/Switcher";
import usePromotions from "common/datahooks/usePromotions";

import useWorkspaceMembers from "../../datahooks/useWorkspaceMembers";

import InvitationImage from "assets/images/invitation.png";

export default function InviteMember() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const {
    members,
    inviteMember,
    isInvitingMember,
    inviteMemberError,
    resetInviteMember,
  } = useWorkspaceMembers();

  const { appSumoPromotion } = usePromotions();
  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({
    resolver: valibotResolver(EmailSchema),
  });

  const [selectedRole, setSelectedRole] = useState<UserRole>("member");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const filteredRolesArray = typedObjectEntries(userRoles).filter(
    ([role]) => role !== "owner",
  );

  const roleOptions = filteredRolesArray.map(([role, { label }]) => ({
    label,
    value: role,
  }));

  function resetStates() {
    resetForm();
    resetInviteMember();
  }

  function onSubmit({ email }: { email: string }): void {
    inviteMember({
      email,
      role: selectedRole,
    }).then(() => {
      setIsDialogOpen(false);
      toast.success("Invite successfully sent");
      resetStates();
    });
  }

  let errorMessage: string;

  if (inviteMemberError) {
    if (
      inviteMemberError instanceof HTTPError &&
      inviteMemberError.type === "CustomerIsAlreadyMember"
    ) {
      errorMessage = "User is already a member of this workspace";
    } else {
      errorMessage = "Error inviting member, please try again later";
    }
  }

  const content = (
    <div className="flex flex-col items-center justify-center gap-6">
      <img
        src={InvitationImage}
        width={364}
        height={266}
        className="mx-auto"
        alt="Invite Member"
      />

      <div className="flex flex-col items-center gap-2">
        <h3 className="text-headline-2xl-bold">Invite a member</h3>

        <span className="mx-auto w-full max-w-[400px] text-center text-black-500">
          Invite someone to this workspace. They will get an email invite with a
          link to join you
        </span>
      </div>

      <Switcher
        onOptionSelect={(value) => setSelectedRole(value as UserRole)}
        options={roleOptions}
        selectedOption={selectedRole}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col gap-4"
      >
        <Input
          {...register("email", { onChange: resetInviteMember })}
          error={errorMessage || errors.email?.message}
          placeholder="Teammate email"
          aria-label="Teammate email address"
          className="w-full"
          variant="lg"
        />

        <Button
          type="submit"
          size="lg"
          className="w-full"
          isLoading={isInvitingMember}
        >
          Invite
        </Button>
      </form>
    </div>
  );

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isDialogOpen}
      onOpenChange={setIsDialogOpen}
      onAfterClose={resetStates}
    >
      <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
        {(isDisabled) => (
          <Trigger asChild>
            <Button
              leftIcon={<PlusCircle weight="fill" />}
              disabled={
                isDisabled ||
                (appSumoPromotion &&
                  members.length >= appSumoPromotion.max_members)
              }
              size="md"
              className={appSumoPromotion ? "w-auto" : "w-full md:w-auto"}
            >
              Invite teammate
            </Button>
          </Trigger>
        )}
      </RestrictedComponent>

      <Content className="md:max-w-[540px]">{content}</Content>
    </Component>
  );
}
