import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { del, post } from "common/helpers/HTTP";
import { Promotion, Workspace } from "common/types";
import useWorkspaceCacheUpdate from "common/datahooks/useWorkspaceCacheUpdate";

async function addLifetimeLicensesRequest(
  workspaceId: string,
  count: number,
  source: "appsumo" | "ltd",
) {
  await post(`workspaces/${workspaceId}/licenses/lifetime`, {
    count,
    source,
  });
}
async function createAppSumoPromotionRequest(code: string): Promise<Promotion> {
  return (await post(`customers/promotions/appsumo`, { code })).promotion;
}
async function resetSeatsRequest(source: "appsumo" | "ltd") {
  await del(`customers/promotions/${source}`);
}
export default function usePromotionsMutations() {
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();
  const queryClient = useQueryClient();

  const {
    mutateAsync: createAppSumoPromotion,
    isPending: isCreatingAppSumoPromotion,
    error: createAppSumoPromotionError,
  } = useMutation({
    mutationFn: ({ code }: { code: string }) =>
      createAppSumoPromotionRequest(code),
    onSuccess: (promotion) => {
      queryClient.setQueryData<Promotion[]>(["promotions"], (prevPromotions) =>
        prevPromotions ? [...prevPromotions, promotion] : [promotion],
      );
    },
  });
  const { mutateAsync: resetSeats, isPending: isResettingSeats } = useMutation({
    mutationFn: ({ source }: { source: "appsumo" | "ltd" }) =>
      resetSeatsRequest(source),
    onSuccess: (_, { source }) => {
      queryClient.setQueryData<Workspace[]>(["workspaces"], (prevWorkspaces) =>
        produce(prevWorkspaces, (draftWorkspaces) => {
          draftWorkspaces.forEach((draftWorkspace) => {
            draftWorkspace.licenses = draftWorkspace.licenses.filter(
              (license) => license.source !== source,
            );
          });
        }),
      );
    },
  });
  const {
    mutateAsync: addLifetimeLicenses,
    isPending: isAddingLifetimeLicenses,
  } = useMutation({
    mutationFn: ({
      workspaceId,
      count,
      source,
    }: {
      workspaceId: string;
      count: number;
      source: "ltd" | "appsumo";
    }) => addLifetimeLicensesRequest(workspaceId, count, source),
    onSuccess: (_, { count, source, workspaceId }) => {
      queryClient.setQueryData<Promotion[]>(
        ["promotions"],
        (prevPromotions) => {
          if (prevPromotions) {
            return produce(prevPromotions, (draftPromotions) => {
              for (let i = 0; i < draftPromotions.length; i += 1) {
                if (draftPromotions[i].type === source) {
                  draftPromotions[i].unassigned_license_count -= count;
                }
              }
            });
          }
        },
      );
      updateCachedWorkspace(workspaceId, (draftWorkspace) => {
        const { licenses } = draftWorkspace;
        for (let i = 0; i < count; i += 1) {
          licenses.push({
            status: "active",
            expires_at: 0,
            source,
          });
        }
      });
    },
  });
  return {
    createAppSumoPromotion,
    isCreatingAppSumoPromotion,
    createAppSumoPromotionError,
    resetSeats,
    isResettingSeats,
    addLifetimeLicenses,
    isAddingLifetimeLicenses,
  };
}
