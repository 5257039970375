import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { LicenseId, PlanId } from "common/types";

import { post } from "../helpers/HTTP";

async function estimateCreationRequest(
  workspaceId: string,
  planId: PlanId,
  licenseId: LicenseId,
  quantity: number,
): Promise<{ amountDue: number; nextBillingAt: number }> {
  const { estimate } = await post(
    `workspaces/${workspaceId}/subscriptions/estimates/create`,
    {
      items: [
        {
          id: planId,
        },
        ...(quantity > 0
          ? [
              {
                id: licenseId,
                quantity,
              },
            ]
          : []),
      ],
    },
  );

  const {
    invoice_estimate: invoiceEstimate,
    subscription_estimate: subscriptionEstimate,
    next_invoice_estimate: nextInvoiceEstimate,
  } = estimate;

  return {
    amountDue: invoiceEstimate
      ? invoiceEstimate.amount_due
      : nextInvoiceEstimate.amount_due,
    nextBillingAt: subscriptionEstimate.next_billing_at,
  };
}

export default function useEstimateCreation({
  planId,
  quantity,
}: {
  planId: PlanId;
  quantity: number;
}) {
  const { id: workspaceId, subscription } = useSelectedWorkspaceContext();

  const { data: estimateCreation, isLoading: isLoadingEstimateCreation } =
    useQuery({
      queryKey: ["estimateCreation", planId, quantity],
      queryFn: () =>
        estimateCreationRequest(
          workspaceId,
          planId,
          planId === "Basic-USD-Monthly"
            ? "Outreach-License-USD-Monthly"
            : "Outreach-License-USD-Yearly",
          quantity,
        ),
      enabled: !subscription, // The request should only be called if there is no subscription
    });

  return { estimateCreation, isLoadingEstimateCreation };
}
