import { PlusCircle } from "@phosphor-icons/react";
import React from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import RestrictedComponent from "common/components/RestrictedComponent";
import CreateNewCampaign from "common/components/CreateNewCampaign";
import { Button } from "common/components/ui/Button";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/no-profiles.png";

export default function NoLeadsPlaceholder() {
  const workspace = useSelectedWorkspaceContext();
  const hasAccount = !!workspace.accounts.length;

  return (
    <EmptyPlaceholder
      title={
        hasAccount ? "No leads found" : "Add a LinkedIn account to use LeadsCRM"
      }
      subtitle={
        hasAccount
          ? "Leads are your LinkedIn connections and accounts you can chat with. Whenever you get new lead from campaigns, they will show up here"
          : "LeadsCRM will automatically sync with your LinkedIn account, and update every time you get a new connection or conversation"
      }
      imageSrc={EmptyPlaceholderImage}
      actionComponent={
        <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
          {(isDisabled) => (
            <CreateNewCampaign
              triggerButton={
                <Button
                  leftIcon={<PlusCircle weight="fill" />}
                  disabled={isDisabled}
                >
                  {hasAccount ? "Create campaign" : "Connect account"}
                </Button>
              }
            />
          )}
        </RestrictedComponent>
      }
    />
  );
}
