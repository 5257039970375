import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";

import usePromotionsMutations from "../../datahooks/usePromotionsMutations";

export default function AppSumoDialog({
  isOpen,
  code,
  onClose,
}: {
  isOpen: boolean;
  code: string;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  const {
    createAppSumoPromotion,
    isCreatingAppSumoPromotion,
    createAppSumoPromotionError,
  } = usePromotionsMutations();

  useEffect(() => {
    if (code) {
      createAppSumoPromotion({ code });
    }
  }, [code]);

  let title = "You AppSumo licence is activated";
  let subtitle =
    "Your AppSumo licence has just been activated. It will be applied on your next subscription";

  if (isCreatingAppSumoPromotion) {
    title = "Activating your AppSumo plan";
    subtitle =
      "We are activating your lifetime seats and preparing the dashboard. This may take a second";
  } else if (createAppSumoPromotionError) {
    title = "Failed to activate AppSumo plan";
    subtitle =
      "You may have already redeemed this plan. Please contact support if you think this is a mistake";
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && !isCreatingAppSumoPromotion) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-[540px]">
        <h3 className="mb-2 mt-4 text-center text-headline-2xl-bold">
          {title}
        </h3>
        <p className="text-center text-body-14-regular text-black-500">
          {subtitle}
        </p>
        <Button
          size="lg"
          onClick={() => {
            if (createAppSumoPromotionError) {
              onClose();
            } else {
              navigate("/workspaces");
            }
          }}
          className="mt-8"
          isLoading={isCreatingAppSumoPromotion}
        >
          {createAppSumoPromotionError
            ? "Close"
            : "Manage seats and workspaces"}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
