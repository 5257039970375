import { CaretRight, Minus, Plus } from "@phosphor-icons/react";
import React from "react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";
import { PlanId } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "common/components/ui/Tooltip";

import PricingInfo from "./PricingInfo";

const planOptions: {
  value: PlanId;
  label: string;
}[] = [
  {
    value: "Basic-USD-Monthly",
    label: "Monthly",
  },
  {
    value: "Basic-USD-Yearly",
    label: "Yearly",
  },
];

interface ManageSeatsProps {
  licenseCount: number;
  setLicenseCount: (newCount: number) => void;
  selectedPlan: PlanId;
  setSelectedPlan: (newPlan: PlanId) => void;
  goToNextStep: () => void;
}

export default function ManageSeats({
  licenseCount,
  setLicenseCount,
  selectedPlan,
  setSelectedPlan,
  goToNextStep,
}: ManageSeatsProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;
  const { subscription, license_count: workspaceLicenseCount } =
    useSelectedWorkspaceContext();

  const isYearlySubscription = subscription?.pricing_id === "Basic-USD-Yearly";

  const isNextButtonDisabled = subscription
    ? licenseCount === workspaceLicenseCount &&
      selectedPlan === subscription.pricing_id
    : licenseCount === 0;

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold">Manage seats</h3>

      <div
        className={clsx(
          "mx-auto flex w-[224px] items-center gap-x-1 rounded-full p-1",
          isYearlySubscription ? "bg-black-200" : "bg-black-100",
        )}
      >
        {planOptions.map(({ value, label }) => {
          const isSelected = value === selectedPlan;

          if (isYearlySubscription && value === "Basic-USD-Monthly") {
            return (
              <Tooltip key={value}>
                <TooltipTrigger asChild>
                  <Button
                    variant="quaternary-black"
                    size="sm"
                    className="flex-1"
                    disabled
                  >
                    {label}
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  If you want to switch from a yearly to monthly subscription,
                  contact our support team through the live chat
                </TooltipContent>
              </Tooltip>
            );
          }

          return (
            <Button
              onClick={() => setSelectedPlan(value)}
              key={value}
              variant={isSelected ? "primary-black" : "quaternary-black"}
              size="sm"
              className="flex-1"
            >
              {label}
            </Button>
          );
        })}
      </div>

      <div className="my-4 flex items-center justify-between gap-x-6 rounded-2xl bg-purple-100 px-6 py-2 ring ring-purple-200">
        <Button
          intent="iconOnly"
          variant="secondary-purple"
          onClick={() => setLicenseCount(licenseCount - 1)}
          disabled={licenseCount === 0}
        >
          <Minus />
        </Button>
        <span className="flex h-10 flex-1 items-center justify-center rounded-xl bg-white text-body-16-bold text-black-700">
          {licenseCount}
        </span>
        <Button
          intent="iconOnly"
          variant="secondary-purple"
          onClick={() => setLicenseCount(licenseCount + 1)}
        >
          <Plus />
        </Button>
      </div>

      <PricingInfo licenseCount={licenseCount} selectedPlan={selectedPlan} />

      <Footer className="mt-6 md:-mx-16">
        <Button
          rightIcon={<CaretRight />}
          size="lg"
          className="ml-auto"
          onClick={goToNextStep}
          disabled={isNextButtonDisabled}
        >
          Next
        </Button>
      </Footer>
    </>
  );
}
