import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import Skeleton from "common/components/ui/Skeleton";

export default function CampaignInfoLoader() {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const stateTag = <Skeleton className="h-6 w-12" />;
  const subtypeTag = <Skeleton className="h-6 w-20" />;
  const accountTag = <Skeleton className="h-6 w-32 rounded-full" />;
  const actionsDropdown = <Skeleton className="size-10 rounded-full" />;
  const campaignName = <Skeleton className="h-[22px] w-40 md:mx-3" />;
  const campaignTypeIcon = <Skeleton className="size-12" />;

  let mainContent;
  if (isTabletOrDesktop) {
    mainContent = (
      <>
        {campaignTypeIcon}
        {campaignName}
        {subtypeTag}
        <div className="flex flex-1 items-center justify-end gap-x-4">
          <div className="flex items-center gap-x-1">
            <span className="text-caption-12-regular text-black-500">
              Run by:
            </span>
            {accountTag}
          </div>
          {stateTag}
          <span className="h-10 w-px bg-black-200" />
          {actionsDropdown}
        </div>
      </>
    );
  } else {
    mainContent = (
      <>
        <div className="flex w-full items-center">
          {campaignTypeIcon}
          <div className="ml-3 w-[calc(100%_-_133px)]">
            {campaignName}
            <div className="mt-1 flex items-center gap-2">
              {subtypeTag} {stateTag}
            </div>
          </div>
          <div className="flex flex-1 items-center justify-end gap-x-4">
            <span className="h-10 w-px bg-black-200" />
            {actionsDropdown}
          </div>
        </div>
        <span className="my-2 h-px w-full bg-black-200" />
        {accountTag}
      </>
    );
  }

  return (
    <section className="mb-4 flex flex-col rounded-2xl border border-black-200 px-3 py-4 md:flex-row md:items-center md:px-4">
      {mainContent}
    </section>
  );
}
