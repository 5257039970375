import React from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useSubscriptionMutations from "common/datahooks/useSubscriptionMutations";

export default function RemoveChangeDialog() {
  const { removeChange, isRemovingChange } = useSubscriptionMutations();

  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <Component>
      <Trigger asChild>
        <Button variant="tertiary-black" className="whitespace-nowrap">
          Remove change
        </Button>
      </Trigger>

      <Content className="md:max-w-[540px]">
        <h3 className="mb-2 text-center text-headline-2xl-bold">
          Are you sure you want to remove this scheduled change?
        </h3>

        <p className="mb-12 text-center text-black-400">
          This will remove all scheduled changes and cancellations for this
          subscription. This will not stop you from managing your subscription,
          if you change your mind
        </p>

        <div className="flex w-full flex-col justify-center gap-3 md:flex-row-reverse">
          <Button
            variant="tertiary-danger"
            className="flex-1"
            size="lg"
            isLoading={isRemovingChange}
            onClick={() => removeChange()}
          >
            Remove change
          </Button>

          <Close asChild>
            <Button
              variant="secondary-black"
              className="flex-1"
              size="lg"
              disabled={isRemovingChange}
            >
              Cancel
            </Button>
          </Close>
        </div>
      </Content>
    </Component>
  );
}
