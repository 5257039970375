import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { Price } from "common/types";

async function getPricingRequest(): Promise<Price[]> {
  return (await get("billing/pricing")).pricing;
}

export default function usePricing() {
  const { data: pricing, isLoading: isLoadingPricing } = useQuery({
    queryKey: ["pricing"],
    queryFn: getPricingRequest,
  });

  return { pricing, isLoadingPricing };
}
