import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { patch, post, del } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  BaseTemplate,
  CreatedTemplate,
  InMailTemplate,
  Template,
} from "common/types";

type TemplateUpdates =
  | BaseTemplate
  | Pick<InMailTemplate, "name" | "message" | "subject">;
async function createTemplateRequest(workspaceId: string, template: Template) {
  const payload = {
    name: template.name.trim(),
    message: template.message.trim(),
    type: template.type,
  };
  if (template.type === "INMAIL_TEMPLATE") {
    (payload as InMailTemplate).subject = template.subject?.trim();
  }
  const { template: newTemplate } = await post(
    `workspaces/${workspaceId}/templates`,
    payload,
  );
  return newTemplate;
}
async function updateTemplateRequest(
  workspaceId: string,
  templateId: string,
  updates: TemplateUpdates,
) {
  const payload = {
    name: updates.name?.trim(),
    message: updates.message?.trim(),
  };
  if ("subject" in updates) {
    (payload as InMailTemplate).subject = updates.subject.trim();
  }
  await patch(`workspaces/${workspaceId}/templates/${templateId}`, payload);
  return { templateId, updates };
}
async function deleteTemplateRequest(workspaceId: string, templateId: string) {
  await del(`workspaces/${workspaceId}/templates/${templateId}`);
  return templateId;
}

export default function useTemplate() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { templatesKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: createTemplate, isPending: isCreatingTemplate } =
    useMutation({
      mutationFn: ({ template }: { template: Template }) =>
        createTemplateRequest(workspaceId, template),
      onSuccess: (template: CreatedTemplate) => {
        toast.success("Template created");
        queryClient.setQueryData(
          templatesKeys.list(),
          (prevTemplates: Template[]) => [template, ...prevTemplates],
        );
      },
    });

  const { mutateAsync: updateTemplate, isPending: isUpdatingTemplate } =
    useMutation({
      mutationFn: ({
        templateId,
        updates,
      }: {
        templateId: string;
        updates: TemplateUpdates;
      }) => updateTemplateRequest(workspaceId, templateId, updates),
      onSuccess: ({ templateId, updates }) => {
        toast.success("Template updated");
        queryClient.setQueryData(
          templatesKeys.list(),
          (prevTemplates: CreatedTemplate[]) => {
            const clonedTemplates = [...prevTemplates];
            const index = clonedTemplates.findIndex(
              ({ id }) => id === templateId,
            );
            clonedTemplates[index] = {
              ...clonedTemplates[index],
              ...updates,
            };
            return clonedTemplates;
          },
        );
      },
    });

  const { mutateAsync: deleteTemplate, isPending: isDeletingTemplate } =
    useMutation({
      mutationFn: ({ templateId }: { templateId: string }) =>
        deleteTemplateRequest(workspaceId, templateId),
      onSuccess: (templateId) => {
        toast.success("Template deleted");
        queryClient.setQueryData(
          templatesKeys.list(),
          (prevTemplates: CreatedTemplate[]) =>
            prevTemplates.filter(({ id }) => id !== templateId),
        );
      },
    });

  return {
    updateTemplate,
    isUpdatingTemplate,
    createTemplate,
    isCreatingTemplate,
    deleteTemplate,
    isDeletingTemplate,
  };
}
