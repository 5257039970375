import React, { useEffect } from "react";
import { GearSix } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import Label from "common/components/ui/Label";
import Checkbox from "common/components/ui/Checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";

export default function SettingsPopup({
  ctrlToSend,
  setCtrlToSend,
  isDisabled,
}: {
  ctrlToSend: boolean;
  setCtrlToSend: (ctrlToSend: boolean) => void;
  isDisabled: boolean;
}) {
  useEffect(() => {
    localStorage.setItem("ctrlToSend", ctrlToSend.toString());
  }, [ctrlToSend]);

  const options = [
    {
      id: "enter-send",
      isEnabled: false,
      label: "Enter to Send",
      description: "Ctrl + Enter to add a new line",
    },
    {
      id: "enter-new-line",
      isEnabled: true,
      label: "Enter to add a new line",
      description: "Ctrl + Enter will send a message",
    },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={isDisabled}>
        <Button intent="iconOnly" variant="quaternary-black" size="xs">
          <GearSix />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent side="top" align="end">
        {options.map(({ id, isEnabled, label, description }) => (
          <DropdownMenuItem
            key={id}
            className="flex items-start space-x-2"
            onClick={() => setCtrlToSend(isEnabled)}
          >
            <Checkbox
              checked={ctrlToSend === isEnabled}
              className="mt-0.5"
              id={id}
            />

            <Label htmlFor={id} className="flex flex-col items-start gap-0.5">
              <span className="text-button-14">{label}</span>

              <span className="text-caption-12-regular text-black-400">
                {description}
              </span>
            </Label>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
