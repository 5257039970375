import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useBilling from "../../datahooks/useBilling";
import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";

export default function Invoices() {
  const { invoices, isLoadingInvoices } = useBilling();
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Table = isTabletOrDesktop ? DesktopTable : MobileList;

  return <Table invoices={invoices ?? []} isLoading={isLoadingInvoices} />;
}
