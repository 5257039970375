import React, { useState } from "react";
import { FunnelSimple } from "@phosphor-icons/react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import RenderIf from "common/components/RenderIf";

interface FilterConversationsProps {
  selectedAccountIds: string[];
  onApplyFilters: (selectedFilters: string[]) => void;
}

export default function FilterConversations({
  selectedAccountIds,
  onApplyFilters,
}: FilterConversationsProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  function applyFilters(): void {
    onApplyFilters(selectedFilters);
    setIsDialogOpen(false);
  }

  function clearFilters(): void {
    setSelectedFilters([]);
  }

  function handleDialogChange(isOpen: boolean): void {
    setIsDialogOpen(isOpen);

    if (isOpen) {
      setSelectedFilters(selectedAccountIds);
    }
  }

  return (
    <Component open={isDialogOpen} onOpenChange={handleDialogChange}>
      <Trigger asChild>
        <Button
          intent="iconOnly"
          variant="secondary-black"
          size="lg"
          className="group relative"
        >
          <FunnelSimple />

          <RenderIf condition={selectedAccountIds.length > 0}>
            <div className="absolute right-3.5 top-4 size-1.5 rounded-full bg-purple-500 outline outline-black-100 group-hover:outline-black-200" />
          </RenderIf>
        </Button>
      </Trigger>

      <Content className="w-full md:max-w-[540px]">
        <h2 className="pb-6 text-center text-headline-2xl-bold">
          Inbox filters
        </h2>

        <div className="flex flex-col gap-2">
          <span className="text-button-16">Filter by account</span>

          <WorkspaceAccountSelector
            selectedAccountIds={selectedFilters}
            setSelectedAccountIds={setSelectedFilters}
          />
        </div>

        <div className="mt-6 grid grid-cols-2 gap-4">
          <Button
            variant="tertiary-black"
            size="lg"
            onClick={clearFilters}
            disabled={!selectedFilters.length}
          >
            Clear all filters
          </Button>

          <Button size="lg" onClick={applyFilters}>
            Apply filters
          </Button>
        </div>
      </Content>
    </Component>
  );
}
