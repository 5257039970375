import React from "react";

import { Tag } from "common/components/ui/Tag";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

export default function PromotionsTag() {
  const { licenses } = useSelectedWorkspaceContext();

  const promotionLicensesCount = licenses.reduce(
    (accumulator, currentValue) => {
      if (currentValue.source !== "aimfox") {
        accumulator[currentValue.source] += 1;
      }
      return accumulator;
    },
    { appsumo: 0, ltd: 0 },
  );

  if (promotionLicensesCount.appsumo || promotionLicensesCount.ltd) {
    let label;
    if (promotionLicensesCount.appsumo && promotionLicensesCount.ltd) {
      label = "Total";
    } else {
      label = `${
        promotionLicensesCount.appsumo
          ? promotionLicensesCount.appsumo
          : promotionLicensesCount.ltd
      } lifetime seats`;
    }
    return (
      <Tag
        variant={promotionLicensesCount.ltd ? "primary-black" : "yellow"}
        className={
          promotionLicensesCount.appsumo &&
          !promotionLicensesCount.ltd &&
          "bg-appsumoYellow text-black-950"
        }
      >
        {label}
      </Tag>
    );
  }
}
