import { useMutation } from "@tanstack/react-query";

import { post, del } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { WorkspaceAccount } from "common/types";
import useAppStore from "common/hooks/useAppStore";

import useWorkspaceCacheUpdate from "./useWorkspaceCacheUpdate";

async function loginRequest(
  workspaceId: string,
  loginData: { email: string; password: string },
): Promise<
  | {
      loginResult: "PASS";
      profile: WorkspaceAccount;
    }
  | {
      loginResult: "CHALLENGE";
      challengeType:
        | "EmailPinChallenge"
        | "SMSPinChallenge"
        | "AuthenticatorAppChallenge"
        | "CaptchaChallenge"
        | "AppChallenge";
    }
> {
  const {
    login_result: loginResult,
    challenge_type: challengeType,
    profile,
  } = await post(`workspaces/${workspaceId}/accounts/login`, {
    ...loginData,
    debug: useAppStore.getState().debugOptions.shouldDebugLogin,
  });
  return { loginResult, challengeType, profile };
}
async function resolveChallengeRequest(
  workspaceId: string,
  email: string,
  pin: string,
): Promise<{
  loginResult: "PASS";
  profile: WorkspaceAccount;
}> {
  const { login_result: loginResult, profile } = await post(
    `workspaces/${workspaceId}/accounts/challenge/${email}/resolve`,
    { pin },
  );
  return { loginResult, profile };
}
async function resendChallengeRequest(workspaceId: string, email: string) {
  await post(`workspaces/${workspaceId}/accounts/challenge/${email}/resend`);
}
async function deleteAccountRequest(workspaceId: string, accountId: string) {
  await del(`workspaces/${workspaceId}/accounts/${accountId}`);
}

export default function useWorkspaceAccountMutations() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();

  const { mutateAsync: login, isPending: isLoggingIn } = useMutation({
    mutationFn: ({
      loginData,
    }: {
      loginData: { email: string; password: string };
    }) => loginRequest(workspaceId, loginData),
    onSuccess: (response) => {
      if (response.loginResult === "PASS") {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          draftWorkspace.accounts.push(response.profile);
        });
      }
    },
  });
  const { mutateAsync: deleteAccount, isPending: isDeletingAccount } =
    useMutation({
      mutationFn: ({ accountId }: { accountId: string }) =>
        deleteAccountRequest(workspaceId, accountId),
      onSuccess: (_, { accountId }) => {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          draftWorkspace.accounts = draftWorkspace.accounts.filter(
            ({ id }) => id !== accountId,
          );
        });
      },
    });
  const { mutateAsync: resolveChallenge, isPending: isResolvingChallenge } =
    useMutation({
      mutationFn: ({ email, pin }: { email: string; pin: string }) =>
        resolveChallengeRequest(workspaceId, email, pin),
      onSuccess: ({ loginResult, profile }) => {
        if (loginResult === "PASS") {
          updateCachedWorkspace(workspaceId, (draftWorkspace) => {
            draftWorkspace.accounts.push(profile);
          });
        }
      },
    });

  const { mutateAsync: resendChallenge, isPending: isResendingChallenge } =
    useMutation({
      mutationFn: ({ email }: { email: string }) =>
        resendChallengeRequest(workspaceId, email),
    });

  return {
    login,
    isLoggingIn,
    deleteAccount,
    isDeletingAccount,
    resolveChallenge,
    isResolvingChallenge,
    resendChallenge,
    isResendingChallenge,
  };
}
