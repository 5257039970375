import React from "react";

import CSVFileUpload from "common/components/CSVFileUpload";
import useLinkedInUrls from "common/hooks/useLinkedInUrls";

import useTargeting from "../../../../datahooks/useTargeting";
import Stepper from "../../Stepper";

const maxUrls = 1000;

export default function List() {
  const { linkedInUrls, setLinkedInUrls, invalidUrls, validUrls } =
    useLinkedInUrls();
  const { setTargeting, isSettingTargeting } = useTargeting();

  function goToNextStep() {
    setTargeting({
      campaignTargeting: {
        identifiers: linkedInUrls.map((url) => url.split("/")[4]),
      },
    });
  }

  return (
    <>
      <h2 className="mb-1 mt-4 text-headline-xl-bold">Custom Campaign</h2>
      <p className="mb-4 text-black-500 md:mb-10">
        List campaigns allow you to create an extremely targeted audience by
        providing a list of LinkedIn account URLs. This can be either a series
        of links, like a column from one of your marketing sheets, or a .CSV
        file
      </p>
      <div className="mx-auto w-full max-w-[750px]">
        <CSVFileUpload
          lineItems={linkedInUrls}
          setLineItems={setLinkedInUrls}
          errorLines={invalidUrls}
          maxAllowedItems={maxUrls}
        />
      </div>
      <Stepper
        selectedIndex={0}
        goToNextStep={goToNextStep}
        isNextButtonLoading={isSettingTargeting}
        isNextStepDisabled={!validUrls.length || invalidUrls.length}
      />
    </>
  );
}
