import React from "react";
import { PlusCircle } from "@phosphor-icons/react";

import useCampaigns from "common/datahooks/useCampaigns";
import { Button } from "common/components/ui/Button";
import RestrictedComponent from "common/components/RestrictedComponent";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";
import CreateNewCampaign from "common/components/CreateNewCampaign";
import NoCampaignsPlaceholder from "common/components/NoCampaignsPlaceholder";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useCampaignsTable from "./useCampaignsTable";
import StateFilter from "./StateFilter";
import CampaignsTable from "./CampaignsTable";
import MobileFilters from "./MobileFilters";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

export default function CampaignsList() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { campaigns, isLoadingCampaigns, campaignsError, refetchCampaigns } =
    useCampaigns();

  const {
    filteredCampaigns,
    hasFilters,
    searchText,
    setSearchText,
    campaignState,
    setCampaignState,
    clearFilters,
    selectedAccountIds,
    setSelectedAccountIds,
  } = useCampaignsTable(campaigns);

  if (campaignsError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Campaigns"
        onRetry={refetchCampaigns}
      />
    );
  }
  if (!isLoadingCampaigns && (!campaigns || !campaigns.length)) {
    return <NoCampaignsPlaceholder />;
  }
  const currentPageCampaigns = filteredCampaigns.length
    ? filteredCampaigns
    : new Array(7).fill({});

  return (
    <>
      {/* Top Section */}
      <div className="flex flex-col-reverse items-center justify-between md:flex-row">
        <div className="flex w-full items-center gap-2">
          <SearchInput
            className="max-md:flex-1 md:w-80"
            placeholder="Search campaign"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onClear={() => setSearchText("")}
          />
          {isTabletOrDesktop ? (
            <>
              <StateFilter
                selectedFilter={campaignState}
                setFilter={setCampaignState}
                campaigns={campaigns}
              />
              <WorkspaceAccountSelector
                className="w-44"
                selectedAccountIds={selectedAccountIds}
                setSelectedAccountIds={setSelectedAccountIds}
              />
            </>
          ) : (
            <MobileFilters
              campaigns={campaigns}
              currentFilters={{ selectedAccountIds, campaignState }}
              clearFilters={clearFilters}
              applyFilters={(newSelectedAccountIds, newCampaignState) => {
                setCampaignState(newCampaignState);
                setSelectedAccountIds(newSelectedAccountIds);
              }}
            />
          )}
        </div>
        <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
          {(isDisabled) => (
            <CreateNewCampaign
              triggerButton={
                <Button
                  leftIcon={<PlusCircle weight="fill" />}
                  className="max-md:mb-4 max-md:w-full"
                  disabled={isDisabled}
                >
                  Create campaign
                </Button>
              }
            />
          )}
        </RestrictedComponent>
      </div>
      {hasFilters && !filteredCampaigns.length ? (
        <EmptyPlaceholder
          title="No results found"
          subtitle="There were no results that match your criteria"
          imageSrc={NoResultsImage}
          actionComponent={<Button onClick={clearFilters}>Clear Search</Button>}
        />
      ) : (
        <CampaignsTable
          isLoading={isLoadingCampaigns}
          campaigns={currentPageCampaigns}
        />
      )}
    </>
  );
}
