import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Conversation } from "../types";

const itemsPerPage = 15;

async function getConversations(
  workspaceId: string,
  keywords: string,
  selectedAccountIds: string[],
  before: number | "now",
): Promise<Conversation[]> {
  const searchParams = new URLSearchParams();
  searchParams.append("count", itemsPerPage.toString());
  searchParams.append(
    "before",
    (before === "now" ? Date.now() : before).toString(),
  );

  if (keywords) {
    searchParams.append("keywords", keywords);
  }

  if (selectedAccountIds.length) {
    searchParams.append("account_ids", JSON.stringify(selectedAccountIds));
  }

  return (
    await get(
      `workspaces/${workspaceId}/conversations?${searchParams.toString()}`,
    )
  ).conversations;
}
export default function useConversations(
  {
    keywords,
    versionTag,
    selectedAccountIds = [],
  }: {
    keywords?: string;
    versionTag: string;
    selectedAccountIds?: string[];
  } = { versionTag: "" },
) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { conversationsKeys } = getQueryKeys(workspaceId);

  const {
    isLoading: isLoadingConversations,
    data: conversations,
    fetchNextPage: loadNextConversations,
    hasNextPage: hasMoreConversations,
    error: conversationsError,
    refetch: refetchConversations,
  } = useInfiniteQuery<Conversation[], number | "now">({
    queryKey: conversationsKeys.list(keywords, selectedAccountIds),
    queryFn: ({ pageParam }) =>
      getConversations(
        workspaceId,
        keywords,
        selectedAccountIds,
        pageParam as number | "now",
      ),
    refetchOnMount: false,
    initialPageParam: "now",
    getNextPageParam: (lastPage) => {
      if (lastPage.length) {
        return lastPage[lastPage.length - 1].last_message.created_at;
      }
      return undefined;
    },
  });

  useEffect(() => {
    if (versionTag) {
      refetchConversations();
    }
  }, [versionTag]);

  return {
    conversations: conversations?.pages.flat() as Conversation[],
    isLoadingConversations,
    loadNextConversations,
    hasMoreConversations,
    conversationsError,
    refetchConversations,
  };
}
