import React, { useState } from "react";
import { MagnifyingGlass, PlusCircle } from "@phosphor-icons/react";

import { Input } from "common/components/ui/Input";
import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import LinkedInLogin from "common/components/LinkedInLogin/LinkedInLogin";

import AccountsTable from "./AccountsTable";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";
import ConnectAccountImage from "assets/images/empty-placeholders/connect-account.png";

export default function LinkedInAccounts() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const {
    license_count: licenseCount,
    licenses,
    accounts,
  } = useSelectedWorkspaceContext();
  const [isAddAccountOpen, setIsAddAccountOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const filteredAccounts = accounts.filter(({ full_name }) =>
    full_name.trim().toLowerCase().includes(searchText.trim().toLowerCase()),
  );

  const mainComponent =
    searchText.trim() && !filteredAccounts.length ? (
      <EmptyPlaceholder
        imageSrc={NoResultsImage}
        title="No results found"
        subtitle="There were no results found for your search. Try searching for something else"
        actionComponent={
          <Button onClick={() => setSearchText("")}>Clear Search</Button>
        }
      />
    ) : (
      <AccountsTable accounts={filteredAccounts} />
    );

  const addAccountButton = (
    <Button
      leftIcon={<PlusCircle weight="fill" />}
      onClick={() => setIsAddAccountOpen(true)}
    >
      Add account
    </Button>
  );

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <>
      {accounts.length ? (
        <div className="flex grow flex-col">
          <div className="flex flex-col-reverse justify-between gap-y-3 md:flex-row md:items-center">
            <Input
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
              className="md:w-80"
              leftComponent={<MagnifyingGlass className="text-black-400" />}
              placeholder="Search accounts"
            />
            <div className="flex flex-col gap-3 md:flex-row md:items-center">
              <span className="text-caption-12-regular text-black-950 max-md:mr-auto md:text-button-16 md:text-black-400">
                No. of seats:{" "}
                <span className="text-black-950">
                  {licenses.filter(({ account_id }) => !!account_id).length}/
                  {licenseCount}
                </span>
              </span>
              {addAccountButton}
            </div>
          </div>
          {mainComponent}
        </div>
      ) : (
        <EmptyPlaceholder
          imageSrc={ConnectAccountImage}
          title="No LinkedIn accounts added"
          subtitle="Add a LinkedIn account to start using campaigns, leads and inbox. You can add unlimited accounts"
          actionComponent={addAccountButton}
        />
      )}
      <Component open={isAddAccountOpen} onOpenChange={setIsAddAccountOpen}>
        <Content className="md:w-[540px]">
          <LinkedInLogin
            goBack={() => setIsAddAccountOpen(false)}
            onSuccess={() => setIsAddAccountOpen(false)}
          />
        </Content>
      </Component>
    </>
  );
}
