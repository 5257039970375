import React, { useState } from "react";
import {
  DotsThreeVertical,
  Trash,
  UserCheck,
  UserMinus,
  Link as LinkIcon,
  CalendarX,
} from "@phosphor-icons/react";

import { WorkspaceAccount } from "common/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import Licenses from "common/components/Licenses";
import useValidateAccountLicense from "common/hooks/useValidateAccountLicense";

export default function AccountActions({
  account,
  setAction,
}: {
  account: WorkspaceAccount;
  setAction: (
    action: "delete" | "reconnect" | "license" | "reactivate",
  ) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");

  const isLicenseValid = useValidateAccountLicense(account.id);
  const { state, license } = account;

  function handleLicense() {
    if (isLicenseValid) {
      setAction("license");
    } else {
      setIsOpen(true);
    }
  }

  let actionComponent;
  const dropdownMenuItems = [
    {
      label: `${license ? "Unassign" : "Assign"} seat`,
      icon: license ? <UserMinus size={20} /> : <UserCheck size={20} />,
      onClick: handleLicense,
    },
  ];

  if (state !== "LoggedIn") {
    const label = "Reconnect";
    const icon = <LinkIcon size={20} />;
    const onClick = () => setAction("reconnect");

    actionComponent = (
      <Button
        size={isTabletOrDesktop ? "md" : "lg"}
        variant="secondary-black"
        leftIcon={isTabletOrDesktop && icon}
        onClick={onClick}
      >
        {label}
      </Button>
    );
    dropdownMenuItems.unshift({ label, icon, onClick });
  } else if (license && license.expires_at) {
    const label = "Remove cancellation";
    const icon = <CalendarX size={20} />;
    const onClick = () => setAction("reactivate");

    actionComponent = (
      <Button
        size={isTabletOrDesktop ? "md" : "lg"}
        variant={isTabletOrDesktop ? "secondary-black" : "primary-black"}
        leftIcon={isTabletOrDesktop && icon}
        onClick={onClick}
      >
        {label}
      </Button>
    );
    dropdownMenuItems.unshift({ label, icon, onClick });
  }

  const deleteAccountComponent = (
    <DropdownMenuItem
      onClick={!license && (() => setAction("delete"))}
      className={
        license
          ? "cursor-not-allowed bg-black-200 text-black-400 focus:bg-black-200"
          : "text-red-500"
      }
    >
      <Trash size={20} />
      Delete account
    </DropdownMenuItem>
  );
  return (
    <>
      <div className="flex flex-col justify-end gap-x-3 gap-y-2 md:flex-row">
        {actionComponent}
        {isTabletOrDesktop ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary-black" intent="iconOnly">
                <DotsThreeVertical />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {dropdownMenuItems.map(({ icon, label, onClick }) => (
                <DropdownMenuItem key={label} onClick={onClick}>
                  {icon}
                  {label}
                </DropdownMenuItem>
              ))}
              {license ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    {deleteAccountComponent}
                  </TooltipTrigger>
                  <TooltipContent side="bottom" sideOffset={14}>
                    You cannot delete an account with an assigned seat
                  </TooltipContent>
                </Tooltip>
              ) : (
                deleteAccountComponent
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <>
            <Button
              size="lg"
              variant={license ? "tertiary-black" : "primary-black"}
              onClick={handleLicense}
            >
              {license ? "Unassign" : "Assign"} seat
            </Button>
            <RenderIf condition={!license}>
              <Button
                size="lg"
                variant="tertiary-danger"
                onClick={() => setAction("delete")}
              >
                Delete account
              </Button>
            </RenderIf>
          </>
        )}
      </div>

      <RenderIf condition={!isLicenseValid}>
        <Licenses
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          accountId={account.id}
        />
      </RenderIf>
    </>
  );
}
