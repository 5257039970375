import React, { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Campaign } from "common/types";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import RestrictedComponent from "common/components/RestrictedComponent";
import RenderIf from "common/components/RenderIf";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";

import useCampaignMutations from "../../datahooks/useCampaignMutations";
import { campaignStates, campaignOutreachOptions } from "../../constants";

interface CampaignPreviewProps {
  campaign: Campaign;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function PreviewItem({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) {
  return (
    <div className="flex justify-between">
      <span className="text-black-400">{label}</span>
      {value}
    </div>
  );
}

export default function CampaignPreview({
  campaign,
  isOpen,
  setIsOpen,
}: CampaignPreviewProps) {
  const [currentStep, setCurrentStep] = useState<"preview" | "delete">(
    "preview",
  );
  const navigate = useNavigate();
  const { deleteCampaign, isDeletingCampaign } = useCampaignMutations();

  if (!campaign) return null;

  const {
    type,
    owner,
    outreach_type: outreachType,
    name,
    state,
    id,
    metrics,
    completion,
    target_count: targetCount,
  } = campaign;
  const {
    icon: SubtypeIcon,
    tagVariant,
    label: subtypeLabel,
  } = campaignOutreachOptions[outreachType];
  const { label, tagVariant: stateTagVariant } = campaignStates[state];

  function removeCampaign() {
    deleteCampaign({ campaignId: id, accountId: owner }).then(() =>
      setIsOpen(false),
    );
  }

  const successStringValue =
    campaignOutreachOptions[outreachType].getSuccessString(metrics);
  // this usage is safe since the value comes from the server
  // eslint-disable-next-line no-eval
  const successNumberValue = eval(successStringValue);

  return (
    <Drawer
      open={isOpen}
      onAfterClose={() => setCurrentStep("preview")}
      onOpenChange={setIsOpen}
    >
      <DrawerContent>
        {currentStep === "preview" ? (
          <>
            <div className="mb-6 flex items-center gap-2">
              <CampaignTypeIcon type={campaign.type} />
              <div className="w-[calc(100%_-_48px)]">
                <div className="flex items-center gap-1">
                  <span className="line-clamp-1 text-button-12 capitalize text-black-500">
                    {type} campaign
                  </span>
                  <Tag variant={tagVariant} leftIcon={<SubtypeIcon />}>
                    {subtypeLabel}
                  </Tag>
                </div>

                <h5 className="break-words text-body-16-bold">{name}</h5>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              {/* State Label */}
              <PreviewItem
                label="State"
                value={<Tag variant={stateTagVariant}>{label}</Tag>}
              />
              <RenderIf condition={state !== "INIT"}>
                <PreviewItem label="Targets" value={targetCount} />
                <PreviewItem
                  label="Success"
                  value={`(${successStringValue}) ${successNumberValue ? Math.round(successNumberValue * 100) : 0}%`}
                />
                <PreviewItem
                  label="Progress %"
                  value={`${Math.floor(completion * 100)}%`}
                />
              </RenderIf>
            </div>
            <Button
              className="mb-2 mt-12"
              onClick={() => navigate(`${id}/accounts/${owner}`)}
            >
              Campaign details
            </Button>
            <RestrictedComponent
              hiddenForRoles={["member"]}
              disabledForRoles={[]}
            >
              <Button
                variant="quaternary-danger"
                onClick={() => setCurrentStep("delete")}
              >
                Delete Campaign
              </Button>
            </RestrictedComponent>
          </>
        ) : (
          <>
            <h5 className="mb-2 text-center text-headline-2xl-bold">
              Delete Campaign
            </h5>
            <p className="mb-4 text-center text-black-500">
              Are you sure you want to delete campaign &quot;{name}
              &quot;?
            </p>
            <Button
              variant="tertiary-danger"
              className="mb-3"
              onClick={removeCampaign}
              isLoading={isDeletingCampaign}
            >
              Yes
            </Button>
            <Button
              variant="quaternary-black"
              onClick={() => setCurrentStep("preview")}
            >
              No
            </Button>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}
