import { useState } from "react";

const linkedInUrlRegex =
  /https?:\/\/(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9\-%\u0080-\u0F2F]+\/?$/;
export default function useLinkedInUrls() {
  const [linkedInUrls, setLinkedInUrls] = useState([]);

  const { validUrls, invalidUrls } = linkedInUrls.reduce(
    (accumulator, currentValue, currentIndex) => {
      if (currentValue && linkedInUrlRegex.test(currentValue)) {
        accumulator.validUrls.push(currentValue);
      } else {
        accumulator.invalidUrls.push(currentIndex + 1);
      }
      return accumulator;
    },
    { validUrls: [], invalidUrls: [] },
  );

  return { linkedInUrls, setLinkedInUrls, validUrls, invalidUrls };
}
