import React, { useState } from "react";

import { PaymentSource } from "common/types";

import AddPaymentMethod from "./AddPaymentMethod";
import PaymentMethodSelection from "./PaymentMethodSelection";

export interface PaymentMethodProps {
  paymentSource: PaymentSource;
  onBack: () => void;
  onNext?: (paymentSource: PaymentSource) => void;
  onSelect?: (paymentSource: PaymentSource) => void;
}

export default function PaymentMethod({
  paymentSource,
  onBack,
  onNext = null,
  onSelect = null,
}: PaymentMethodProps) {
  // TODO: Handle first step
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] =
    useState(!paymentSource);

  if (isAddingPaymentMethod)
    return (
      <AddPaymentMethod
        onNext={onNext}
        onBack={paymentSource ? () => setIsAddingPaymentMethod(false) : onBack}
      />
    );

  return (
    <PaymentMethodSelection
      selectedPaymentSource={paymentSource}
      addPaymentMethod={() => setIsAddingPaymentMethod(true)}
      onBack={onBack}
      onSelect={onSelect}
    />
  );
}
