import React from "react";
import { useIntercom } from "react-use-intercom";
import { Lifebuoy } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useAppStore from "common/hooks/useAppStore";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import TrialBanner from "common/layouts/WorkspaceLayout/TrialBanner";

import DebugPopup from "./DebugPopup";
import NotificationsPopup from "./NotificationsPopup";
import WorkspacesPopup from "./WorkspacesPopup";

export default function DesktopHeader({
  headerTitle,
}: {
  headerTitle: string;
}) {
  const { subscription } = useSelectedWorkspaceContext();
  const { show } = useIntercom();
  const headerContent = useAppStore((state) => state.headerContent);

  return (
    <header className="flex h-20 w-full items-center justify-between gap-x-4 border-b border-b-black-200 px-4">
      <div className="flex items-center gap-x-1.5">
        <h1 className="text-headline-xl-bold">{headerTitle}</h1>
        {headerContent && headerContent.component}
        {subscription && subscription.status === "in_trial" && <TrialBanner />}
      </div>
      <div className="flex items-center gap-x-4">
        <Button
          intent="iconOnly"
          variant="quaternary-black"
          aria-label="support"
          onClick={show}
        >
          <Lifebuoy weight="fill" />
        </Button>
        {import.meta.env.MODE !== "production" && <DebugPopup />}
        <NotificationsPopup />
        <WorkspacesPopup />
      </div>
    </header>
  );
}
