import { useMutation, useQueryClient } from "@tanstack/react-query";

import { del, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { LinkedInProfile } from "common/types";

async function getProfilesRequest(
  identifiers: string[],
): Promise<{ profiles: LinkedInProfile[]; failed: string[] }> {
  return post(`identifiers`, {
    identifiers,
  });
}
async function addToBlacklistRequest(
  workspaceId: string,
  profiles: LinkedInProfile[],
) {
  await post(`workspaces/${workspaceId}/blacklist`, {
    urns: profiles.map(({ urn }) => urn),
  });
}
async function removeFromBlacklistRequest(
  workspaceId: string,
  targetUrn: string,
) {
  await del(`workspaces/${workspaceId}/blacklist/${targetUrn}`);
}
export default function useBlacklistMutations() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { blacklistKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: addToBlacklist, isPending: isAddingToBlacklist } =
    useMutation({
      mutationFn: ({ profiles }: { profiles: LinkedInProfile[] }) =>
        addToBlacklistRequest(workspaceId, profiles),
      onSuccess: (_, { profiles }) => {
        queryClient.setQueryData<LinkedInProfile[]>(
          blacklistKeys.list(),
          (prevQueryData) => {
            if (prevQueryData) {
              return [...prevQueryData, ...profiles];
            }
            return profiles;
          },
        );
      },
    });

  const {
    mutateAsync: removeFromBlacklist,
    isPending: isRemovingFromBlacklist,
  } = useMutation({
    mutationFn: ({ targetUrn }: { targetUrn: string }) =>
      removeFromBlacklistRequest(workspaceId, targetUrn),
    onSuccess: (_, { targetUrn }) => {
      queryClient.setQueryData<LinkedInProfile[]>(
        blacklistKeys.list(),
        (prevQueryData) => {
          if (prevQueryData) {
            return prevQueryData.filter(({ urn }) => urn !== targetUrn);
          }
        },
      );
    },
  });
  const { mutateAsync: getProfiles, isPending: isGettingProfiles } =
    useMutation({
      mutationFn: ({ identifiers }: { identifiers: string[] }) =>
        getProfilesRequest(identifiers),
    });

  return {
    addToBlacklist,
    isAddingToBlacklist,
    removeFromBlacklist,
    isRemovingFromBlacklist,
    getProfiles,
    isGettingProfiles,
  };
}
