import React, { useState } from "react";

import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";
import { PaymentSource, PlanId } from "common/types";
import useLicenseMutations from "common/datahooks/useLicenseMutations";
import useSubscriptionMutations from "common/datahooks/useSubscriptionMutations";
import { PaymentMethod } from "common/components/PaymentMethod";
import RenderIf from "common/components/RenderIf";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";

import PricingInfo from "../PricingInfo";
import PrimaryPaymentSource from "./PrimaryPaymentSource";

interface SummaryProps {
  licenseCount: number;
  selectedPlan: PlanId;
  accountsToCancel: string[];
  goToFreeTrialSuccess: () => void;
  goToUpdateSubscriptionSuccess: () => void;
  setPaymentSource: (newPaymentSource: PaymentSource) => void;
  goBack: () => void;
  paymentSource: PaymentSource;
  accountId?: string;
}

export default function Summary({
  licenseCount,
  selectedPlan,
  accountsToCancel,
  goToFreeTrialSuccess,
  goToUpdateSubscriptionSuccess,
  setPaymentSource,
  goBack,
  accountId = null,
  paymentSource,
}: SummaryProps) {
  const { primaryPaymentSource, isLoadingPrimaryPaymentSource } =
    usePrimaryPaymentSource();
  const [isEditingPaymentSource, setIsEditingPaymentSource] = useState(false);

  const { subscription } = useSelectedWorkspaceContext();

  const {
    setLicenseCount,
    isSettingLicenseCount,
    assignLicense,
    isAssigningLicense,
  } = useLicenseMutations();
  const { createSubscription, isCreatingSubscription } =
    useSubscriptionMutations();

  const isTabletOrDesktop = useTwBreakpoint("md");
  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  const selectedPaymentSource = primaryPaymentSource ?? paymentSource;

  let buttonText;
  if (!isLoadingPrimaryPaymentSource && !selectedPaymentSource) {
    buttonText = "Add payment method";
  } else if (subscription) {
    buttonText =
      subscription.status === "cancelled"
        ? "Pay and subscribe"
        : "Update subscription";
  } else {
    buttonText =
      selectedPlan === "Basic-USD-Yearly"
        ? "Pay and subscribe"
        : "Start free trial";
  }

  function handleLicenseUpdate() {
    const updateOrCreateSubscription = subscription
      ? setLicenseCount({
          count: licenseCount,
          planId: selectedPlan,
          accountsToCancel,
        })
      : createSubscription({
          licenseCount,
          planId: selectedPlan,
          paymentSourceId: selectedPaymentSource.id,
        });

    updateOrCreateSubscription
      .then(() => {
        if (accountId) {
          return assignLicense({ accountId });
        }
      })
      .then(() => {
        const isYearlyPlan = selectedPlan === "Basic-USD-Yearly";

        if (isYearlyPlan || subscription) {
          goToUpdateSubscriptionSuccess();
        } else {
          goToFreeTrialSuccess();
        }
      });
  }

  const isActionPending =
    isSettingLicenseCount || isCreatingSubscription || isAssigningLicense;

  function addPaymentSource(newPaymentSource: PaymentSource) {
    setPaymentSource(newPaymentSource);
    setIsEditingPaymentSource(false);
  }

  if (isEditingPaymentSource)
    return (
      <PaymentMethod
        paymentSource={selectedPaymentSource}
        onBack={() => setIsEditingPaymentSource(false)}
        onSelect={!subscription && setPaymentSource}
        onNext={!subscription && addPaymentSource}
      />
    );

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold">Summary</h3>

      <div className="flex flex-col gap-6 rounded-2xl bg-black-50 p-4">
        <PrimaryPaymentSource
          isLoading={isLoadingPrimaryPaymentSource}
          paymentSource={selectedPaymentSource}
          editPaymentSource={() => setIsEditingPaymentSource(true)}
        />

        <PricingInfo licenseCount={licenseCount} selectedPlan={selectedPlan} />
      </div>

      <Footer className="mt-6 md:-mx-16">
        <RenderIf condition={!accountId}>
          <Button
            size="lg"
            variant="secondary-black"
            onClick={goBack}
            disabled={isActionPending}
          >
            Back
          </Button>
        </RenderIf>
        <Button
          size="lg"
          className="ml-auto"
          onClick={
            selectedPaymentSource
              ? handleLicenseUpdate
              : () => setIsEditingPaymentSource(true)
          }
          isLoading={isActionPending || isLoadingPrimaryPaymentSource}
        >
          {buttonText}
        </Button>
      </Footer>
    </>
  );
}
