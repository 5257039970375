import React, { useState } from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";
import usePromotions from "common/datahooks/usePromotions";

import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";
import useWorkspaceMembers from "../../datahooks/useWorkspaceMembers";
import InviteMember from "./InviteMemberDialog";

export default function Members() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { members, isLoadingMembers } = useWorkspaceMembers();
  const [searchTerm, setSearchTerm] = useState("");
  const { appSumoPromotion } = usePromotions();

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const filteredMembers =
    members?.filter(({ first_name: firstName, last_name: lastName, email }) => {
      const isMatchingFirstName = firstName
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);

      const isMatchingLastName = lastName
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);

      const isMatchingEmail = email
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);

      return searchTerm
        ? isMatchingFirstName || isMatchingLastName || isMatchingEmail
        : true;
    }) ?? [];

  const Component = isTabletOrDesktop ? DesktopTable : MobileList;

  return (
    <>
      <div className="flex w-full flex-col-reverse items-center justify-between gap-2 max-lg:pb-4 md:flex-row">
        <SearchInput
          className="w-full md:w-80"
          placeholder="Search members"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm("")}
        />

        {!isLoadingMembers && (
          <div className="flex items-center justify-between gap-x-3 max-md:w-full">
            {appSumoPromotion && (
              <span className="text-button-16 text-black-400">
                {members.length}/{appSumoPromotion.max_members} teammates
              </span>
            )}
            <InviteMember />
          </div>
        )}
      </div>

      <Component members={filteredMembers ?? []} isLoading={isLoadingMembers} />
    </>
  );
}
