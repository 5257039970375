import React, { ReactNode, useState } from "react";
import clsx from "clsx";
import { Info } from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { formatPrice } from "common/helpers/utils";
import { Tag } from "common/components/ui/Tag";
import RestrictedComponent from "common/components/RestrictedComponent";
import RenderIf from "common/components/RenderIf";
import { Button } from "common/components/ui/Button";
import PaidPlanDetails from "common/components/PaidPlanDetails";
import useEstimateRenewal from "common/datahooks/useEstimateRenewal";
import Skeleton from "common/components/ui/Skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import Licenses from "common/components/Licenses";

import RemoveChangeDialog from "./RemoveChangeDialog";
import PromotionsTag from "./PromotionsTag";
import { formatBillingDate } from "../../../utils";

interface InfoBlockProps {
  title: string;
  value: ReactNode;
  subText?: string;
}

function InfoBlock({ title, value, subText = null }: InfoBlockProps) {
  return (
    <div className="flex w-full flex-1 flex-row items-center justify-between gap-5 border-b border-black-200 px-2 py-4 max-xl:last:border-b-0 xl:w-52 xl:flex-col xl:items-start xl:justify-start xl:border-b-0 xl:border-r xl:px-8 xl:pt-8 xl:last:border-r-0">
      <span className="text-body-14-bold text-black-500 max-xl:flex-1">
        {title}
      </span>
      <div className="flex flex-col items-end max-xl:flex-1 lg:items-start">
        <span className="text-headline-xl-bold">{value}</span>

        {subText && (
          <p className="text-caption-12-regular text-black-500">{subText}</p>
        )}
      </div>
    </div>
  );
}

export default function WorkspacePlan() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    subscription,
    licenses,
    license_count: licenseCount,
    active_license_count: activeLicenseCount,
  } = useSelectedWorkspaceContext();

  const seatsUsed = licenses.filter((license) => license.account_id).length;
  const { estimateRenewal, isLoadingEstimateRenewal } = useEstimateRenewal();
  const { amountDue, nextBillingAt } = estimateRenewal || {};

  const hasNonRenewingLicense = licenses.some((license) => license.expires_at);

  let buttonComponent;
  let priceValue;

  if (isLoadingEstimateRenewal) {
    priceValue = <Skeleton className="h-7 w-28" />;
  } else {
    priceValue = formatPrice(amountDue / 100);
  }

  if (!subscription) {
    buttonComponent = (
      <Button
        variant="gradient"
        className="max-w-48"
        onClick={() => setIsOpen(true)}
      >
        Start free trial
      </Button>
    );
    priceValue = "Free";
  } else if (licenseCount === 0) {
    buttonComponent = (
      <Button variant="gradient" onClick={() => setIsOpen(true)}>
        Reactivate subscription
      </Button>
    );
  } else if (hasNonRenewingLicense) {
    buttonComponent = <RemoveChangeDialog />;
  } else {
    buttonComponent = (
      <Button className="max-w-48" onClick={() => setIsOpen(true)}>
        Manage subscription
      </Button>
    );
  }

  return (
    <>
      <div className="flex w-full flex-col items-stretch rounded-3xl border border-black-200 bg-whiteGray p-3 xl:flex-row">
        <div
          className={clsx([
            "flex w-full flex-col gap-4 rounded-xl p-4 xl:max-w-[288px]",
            subscription ? "bg-blackPurple text-white" : "bg-darkGray",
          ])}
        >
          <Tag
            size="lg"
            className="w-fit"
            variant={subscription ? "success" : "primary"}
          >
            {subscription ? "Active" : "Free"}
          </Tag>

          <PaidPlanDetails />
        </div>
        <div className="my-4 flex flex-col xl:flex-1 xl:flex-row">
          <InfoBlock
            title="Seats"
            value={
              <div className="flex items-center gap-x-2">
                {licenseCount}&nbsp;
                <PromotionsTag />
              </div>
            }
            subText={`${seatsUsed} used seats`}
          />
          <InfoBlock title="Price" value={priceValue} subText="Monthly" />
          <InfoBlock
            title="Next billing date"
            value={
              subscription ? (
                <div className="flex flex-col">
                  <span className="text-headline-xl-bold">
                    {isLoadingEstimateRenewal ? (
                      <Skeleton className="h-7 w-28" />
                    ) : (
                      formatBillingDate(nextBillingAt * 1000)
                    )}
                  </span>

                  <RenderIf condition={hasNonRenewingLicense}>
                    <Tag variant="yellow" className="mt-1.5 w-fit">
                      Scheduled change
                      <Tooltip>
                        <TooltipTrigger>
                          <Info
                            size={18}
                            weight="fill"
                            className="fill-yellow-500"
                          />
                        </TooltipTrigger>

                        <TooltipContent>
                          <p className="mb-4 text-wrap">
                            This subscription has a scheduled change which will
                            be effective starting from your next billing date
                          </p>

                          <div className="flex flex-col">
                            <span>Current seats: {licenseCount}</span>
                            <span>New seats: {activeLicenseCount}</span>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </Tag>
                  </RenderIf>

                  <RenderIf condition={!subscription}>
                    <Tag size="lg" className="mt-1.5 w-fit" variant="gradient">
                      14 day free trial
                    </Tag>
                  </RenderIf>
                </div>
              ) : (
                <span className="text-headline-xl-bold">-</span>
              )
            }
          />
        </div>
        <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["member"]}>
          <div className="mt-4 flex w-full items-end justify-end lg:ml-auto lg:mt-auto lg:w-auto lg:pb-4 lg:pr-3">
            {buttonComponent}
          </div>
        </RestrictedComponent>
      </div>

      <Licenses isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
