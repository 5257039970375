import React from "react";
import { Export } from "@phosphor-icons/react";

import { LinkedInProfile } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

import { Target } from "../../../types";

function exportToCSV(profiles: LinkedInProfile[]) {
  const columns = ["full_name", "occupation", "location", "profile_url"];

  let csvContent = `${columns.join(",")}\n`;

  profiles.forEach((profile) => {
    // Wrap row in quotes to handle commas
    const row = [
      `"${profile.full_name}"`,
      `"${profile.occupation}"`,
      `${profile.location ? `"${profile.location.name}"` : ""}`,
      `https://linkedin.com/in/${profile.public_identifier}`,
    ].join(",");

    csvContent += `${row}\n`;
  });

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const downloadUrl = URL.createObjectURL(blob);

  return downloadUrl;
}

interface ExportAudienceProps {
  targets: Target[];
  selectedProfiles: LinkedInProfile[];
}

export default function ExportAudience({
  targets,
  selectedProfiles,
}: ExportAudienceProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const profiles = selectedProfiles.length ? selectedProfiles : targets;
  const downloadUrl = exportToCSV(profiles);

  let text = "Export ";
  if (selectedProfiles.length) {
    text += `${selectedProfiles.length}${isTabletOrDesktop ? ` Target${selectedProfiles.length > 1 ? "s" : ""}` : ""}`;
  } else {
    text += "audience";
  }

  return (
    <Button
      className="flex-1"
      variant={selectedProfiles.length ? "secondary-purple" : "secondary-black"}
      size={isTabletOrDesktop ? "lg" : "md"}
      leftIcon={
        (selectedProfiles.length || isTabletOrDesktop) && (
          <Export weight="fill" />
        )
      }
      asChild
    >
      <a href={downloadUrl} download="LinkedInProfiles.csv">
        {text}
      </a>
    </Button>
  );
}
