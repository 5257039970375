import React from "react";
import { SignOut, X } from "@phosphor-icons/react";

import { logout } from "common/helpers/auth";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

import AimfoxLogo from "assets/aimfoxLogos/aimfox-logo-text.svg?react";

type OnboardingId = "createWorkspace" | "invitation";

interface HeaderProps {
  onboardingId: OnboardingId;
  setOnboardingId: (newId: OnboardingId) => void;
}

export default function Header({ onboardingId, setOnboardingId }: HeaderProps) {
  const isDesktop = useTwBreakpoint("lg");

  let buttonComponent;

  if (onboardingId === "createWorkspace") {
    buttonComponent = isDesktop ? (
      <Button variant="secondary-black" onClick={logout} leftIcon={<SignOut />}>
        Log out
      </Button>
    ) : (
      <Button
        intent="iconOnly"
        size="lg"
        variant="secondary-black"
        onClick={logout}
      >
        <SignOut />
      </Button>
    );
  } else if (onboardingId === "invitation") {
    buttonComponent = isDesktop ? (
      <Button
        variant="tertiary-black"
        onClick={() => setOnboardingId("createWorkspace")}
      >
        Leave page
      </Button>
    ) : (
      <Button
        intent="iconOnly"
        size="lg"
        variant="tertiary-black"
        onClick={() => setOnboardingId("createWorkspace")}
      >
        <X />
      </Button>
    );
  }

  return (
    <header className="flex h-20 items-center justify-between px-4 max-lg:shadow-sm lg:h-[88px] lg:border-b lg:border-black-200 lg:px-3">
      <AimfoxLogo />
      {buttonComponent}
    </header>
  );
}
