import React, { useEffect, useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useInboxDraftStore from "common/hooks/useInboxDraftStore";
import useDebounce from "common/hooks/useDebounce";

import MessageField from "./MessageField";
import { Conversation, ConversationMessage } from "../../../types";
import useConversationUpdates from "../../../datahooks/useConversationUpdates";
import { InboxMessageSchema } from "../../../schemas";

interface MessageContainerProps {
  conversation: Conversation;
  editMessage: ConversationMessage;
  cancelEdit: () => void;
}

export default function MessageContainer({
  conversation,
  editMessage,
  cancelEdit,
}: MessageContainerProps) {
  const { participants } = conversation || {};
  const formReturn = useForm<{ message: string }>({
    resolver: valibotResolver(InboxMessageSchema),
    defaultValues: {
      message: "",
    },
  });
  const { watch, handleSubmit, reset } = formReturn;
  const { sendMessage, isSendingMessage } = useConversationUpdates();
  const { drafts, setDraft, removeDraft } = useInboxDraftStore();

  const currentMessage = watch("message");
  const debouncedMessage = useDebounce(currentMessage, 300);

  const { conversation_urn: conversationUrn, owner: accountId } = conversation;

  useLayoutEffect(() => {
    if (!editMessage || conversation) {
      reset({ message: drafts[conversationUrn] || "" });
    }
  }, [editMessage, conversation]);

  useEffect(() => {
    if (debouncedMessage) {
      setDraft(conversationUrn, debouncedMessage);
    } else if (debouncedMessage !== undefined) {
      removeDraft(conversationUrn);
    }
  }, [debouncedMessage]);

  function onSend({ message }: { message: string }) {
    if (isSendingMessage) {
      return;
    }

    reset({ message: "" });

    sendMessage({ accountId, message, recipients: participants }).then(() => {
      setTimeout(() => {
        document
          .getElementById("thread-scroll-container")
          .scrollTo({ behavior: "smooth", top: 0 });
      });
    });
  }

  return (
    <MessageField
      conversation={conversation}
      editMessage={editMessage}
      cancelEdit={cancelEdit}
      onSend={handleSubmit(onSend)}
      isSendingMessage={isSendingMessage}
      formReturn={formReturn}
    />
  );
}
