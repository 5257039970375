import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Button } from "common/components/ui/Button";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import { WorkspaceNameSchema } from "common/schemas";
import useWorkspaceMutations from "common/datahooks/useWorkspaceMutations";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import useWorkspaces from "common/datahooks/useWorkspaces";

import CreateWorkspaceImage from "assets/images/create-workspace.png";

export default function CreateWorkspace({
  closeButton = null,
}: {
  closeButton?: ReactNode;
}) {
  const { goToWorkspace } = useWorkspaceNavigate();
  const { createWorkspace, isCreatingWorkspace } = useWorkspaceMutations();
  const { workspaces } = useWorkspaces();

  const isFirstWorkspace = !workspaces.length;

  function onSubmit({ name }: { name: string }) {
    createWorkspace({ name }).then((workspace) => goToWorkspace(workspace.id));
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: valibotResolver(WorkspaceNameSchema),
  });

  return (
    <>
      <img
        src={CreateWorkspaceImage}
        height={254}
        width={364}
        alt="Workspace Creation"
        className="mx-auto mb-6"
      />
      <h1 className="mb-2 text-center text-headline-2xl-bold">
        {isFirstWorkspace
          ? "Create your first workspace"
          : "Create a new workspace"}
      </h1>
      <p className="mb-12 text-center text-black-500">
        This will be the main place to manage your Aimfox campaigns and
        collaborate with your team
      </p>

      <Label htmlFor="workspaceName" className="mb-1 text-left">
        Workspace name
      </Label>
      <Input
        variant="lg"
        id="workspaceName"
        placeholder="Name your workspace"
        className="mb-6"
        error={errors.name?.message}
        {...register("name")}
      />
      <div className="flex flex-col-reverse gap-4 md:flex-row">
        {closeButton}
        <Button
          size="lg"
          className="flex-1"
          onClick={handleSubmit(onSubmit)}
          isLoading={isCreatingWorkspace}
        >
          Create
        </Button>
      </div>
    </>
  );
}
