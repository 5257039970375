import React, { ReactNode } from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";

import CreateWorkspace from "./CreateWorkspace";

interface CreateWorkspaceModalProps {
  triggerButton: ReactNode;
}

export default function CreateWorkspaceModal({
  triggerButton,
}: CreateWorkspaceModalProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  const closeButton = (
    <Close asChild>
      <Button size="lg" variant="secondary-black" className="flex-1">
        Cancel
      </Button>
    </Close>
  );

  return (
    <Component>
      <Trigger asChild>{triggerButton}</Trigger>
      <Content className="md:max-w-[540px]">
        <CreateWorkspace closeButton={closeButton} />
      </Content>
    </Component>
  );
}
