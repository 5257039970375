import React from "react";
import { Plus } from "@phosphor-icons/react";
import clsx from "clsx";

import Skeleton from "common/components/ui/Skeleton";
import usePaymentSources from "common/datahooks/usePaymentSources";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { paymentMethods, cardIcons } from "common/constants";
import { Button } from "common/components/ui/Button";
import { PaymentSource } from "common/types";

interface PaymentMethodSelectionProps {
  selectedPaymentSource: PaymentSource;
  addPaymentMethod: () => void;
  onBack: () => void;
  onSelect?: (paymentSource: PaymentSource) => void;
}

export default function PaymentMethodSelection({
  selectedPaymentSource,
  addPaymentMethod,
  onBack,
  onSelect = null,
}: PaymentMethodSelectionProps) {
  const { paymentSources, isLoadingPaymentSources } = usePaymentSources();
  const { setPrimaryPaymentSource } = usePrimaryPaymentSource();

  let mainComponent = null;
  if (isLoadingPaymentSources) {
    mainComponent = <Skeleton className="h-14 rounded-2xl" />;
  } else if (paymentSources?.length) {
    mainComponent = (
      <div className="flex max-h-[310px] flex-col gap-y-2 overflow-y-auto scrollbar-thin">
        {paymentSources.map((paymentSource) => {
          const Icon =
            paymentSource.type === "card"
              ? cardIcons[paymentSource.card.brand]
              : paymentMethods[paymentSource.type].icon;

          const isSelected = selectedPaymentSource?.id === paymentSource.id;

          function onPaymentMethodSelect() {
            if (onSelect) {
              onSelect(paymentSource);
            } else {
              setPrimaryPaymentSource({ paymentSource });
            }
          }

          return (
            <button
              className={clsx(
                "flex items-center gap-3 rounded-2xl p-3 transition-colors",
                isSelected
                  ? "border-[3px] border-purple-200 bg-purple-50"
                  : "border-transparent bg-black-100",
              )}
              key={paymentSource.id}
              onClick={onPaymentMethodSelect}
              type="button"
            >
              <Icon />
              <span className="text-body-14-bold">
                {paymentSource.type === "card"
                  ? `Card ending with ${paymentSource.card.last4}`
                  : paymentSource.paypal.email}
              </span>
            </button>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Select your preferred payment method
      </h3>

      <span className="mb-8 text-center text-black-500">
        Set a preferred payment method for your Aimfox subscription. You can
        change this at any time
      </span>

      <div className="mb-8 flex flex-col gap-2">
        {mainComponent}
        <button
          type="button"
          className="flex items-center justify-start gap-2 rounded-2xl border border-dashed border-black-300 px-4 py-2"
          onClick={addPaymentMethod}
        >
          <div className="rounded-full bg-black-900 p-2">
            <Plus color="white" size={20} />
          </div>

          <span className="text-body-14-bold">Add payment method</span>
        </button>
      </div>

      <Button
        className="mr-auto"
        size="lg"
        variant="secondary-black"
        onClick={onBack}
      >
        Back
      </Button>
    </>
  );
}
