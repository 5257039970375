import React, { useState } from "react";
import { clsx } from "clsx";

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import { Promotion } from "common/types";

import AssignSeats from "./AssignSeats";
import Success from "./Success";
import ResetSeats from "./ResetSeats";

export default function ManagePromotion({
  promotion,
}: {
  promotion: Promotion;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [currentStepName, setCurrentStepName] = useState<
    "assign" | "success" | "reset"
  >("assign");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;

  const stepsComponents = {
    assign: (
      <AssignSeats
        promotion={promotion}
        onSuccess={() => setCurrentStepName("success")}
        onReset={() => setCurrentStepName("reset")}
      />
    ),
    success: <Success />,
    reset: (
      <ResetSeats
        goBack={() => setCurrentStepName("assign")}
        source={promotion.type}
      />
    ),
  };

  return (
    <Component
      onAfterClose={() => {
        setCurrentStepName("assign");
      }}
    >
      <Trigger asChild>
        <Button
          variant={
            promotion.type === "appsumo" ? "secondary-black" : "primary-black"
          }
          className={clsx(
            "max-lg:flex-1",
            promotion.type === "appsumo" && "bg-appsumoYellow",
          )}
        >
          Manage {promotion.type === "appsumo" ? "AppSumo" : "lifetime deal"}
        </Button>
      </Trigger>
      <Content className="md:w-[540px]">
        {stepsComponents[currentStepName]}
      </Content>
    </Component>
  );
}
