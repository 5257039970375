import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import useTemplates from "common/datahooks/useTemplates";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import CreateTemplate from "common/components/CreateTemplate";
import SearchInput from "common/components/SearchInput";
import RenderIf from "common/components/RenderIf";

import useTemplatesTable from "./useTemplatesTable";
import TemplatesTable from "./TemplatesTable";
import TemplateTypeFilter from "./TemplateTypeFilter";

import NoResultsImage from "assets/images/empty-placeholders/no-templates.png";
import EmptyPlaceholderImage from "assets/images/empty-placeholders/new-template.png";

export default function TemplatesList() {
  const { t } = useTranslation("templates");
  const [isOpen, setIsOpen] = useState(false);

  const {
    allTemplates,
    isLoadingAllTemplates,
    templatesError,
    refetchTemplates,
  } = useTemplates();

  const {
    filteredTemplates,
    searchText,
    templateType,
    setTemplateType,
    setSearchText,
    hasFilters,
    clearFilters,
  } = useTemplatesTable(allTemplates);

  if (templatesError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Templates"
        onRetry={refetchTemplates}
      />
    );
  }

  const currentPageTemplates = filteredTemplates.length
    ? filteredTemplates
    : new Array(7).fill({});

  let mainContent = (
    <TemplatesTable
      templates={currentPageTemplates}
      isLoading={isLoadingAllTemplates}
    />
  );

  if (!isLoadingAllTemplates && !allTemplates.length) {
    mainContent = (
      <EmptyPlaceholder
        title={t("emptyTemplatesTitle")}
        subtitle={t("emptyTemplatesSubtitle")}
        imageSrc={EmptyPlaceholderImage}
        actionComponent={
          <Button
            leftIcon={<PlusCircle weight="fill" />}
            onClick={() => setIsOpen(true)}
          >
            Create template
          </Button>
        }
      />
    );
  } else if (hasFilters && !filteredTemplates.length) {
    mainContent = (
      <EmptyPlaceholder
        title="No results found"
        subtitle="There were no results found for your search. Try searching for something else"
        imageSrc={NoResultsImage}
        actionComponent={<Button onClick={clearFilters}>Clear Search</Button>}
      />
    );
  }

  return (
    <>
      {/* Top Section */}
      <RenderIf condition={isLoadingAllTemplates || !!allTemplates.length}>
        <div className="flex flex-col-reverse items-center justify-between md:flex-row">
          <div className="flex w-full items-center gap-4">
            <SearchInput
              className="max-md:flex-1 md:w-80"
              placeholder="Search template"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              onClear={() => setSearchText("")}
            />
            <TemplateTypeFilter
              selectedFilter={templateType}
              setFilter={setTemplateType}
              templates={allTemplates}
            />
          </div>
          <Button
            leftIcon={<PlusCircle weight="fill" />}
            onClick={() => setIsOpen(true)}
            className="max-md:mb-4 max-md:w-full"
          >
            Create Template
          </Button>
        </div>
      </RenderIf>

      {mainContent}
      <CreateTemplate isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}
