import { X } from "@phosphor-icons/react";
import React, { useEffect, useRef } from "react";

import SearchInput from "common/components/SearchInput";
import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";

import ExportLeads from "./ExportLeads";
import useLeadsSearchParams from "../../hooks/useLeadsSearchParams";
import { LeadsSearchParams, SelectedFacetParam } from "../../types";

export default function Heading({
  setSearchParams,
  searchParams,
  totalLeads,
  isLoading,
  keywordInputValue,
  setKeywordInputValue,
}: {
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  setSearchParams: (
    searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>,
  ) => void;
  totalLeads: number;
  isLoading: boolean;
  keywordInputValue: string;
  setKeywordInputValue: (keywordInputValue: string) => void;
}) {
  const isDesktop = useTwBreakpoint("lg");
  const timeoutRef = useRef(null);

  const { toggleSearchParam, setSearchParam } = useLeadsSearchParams(
    searchParams,
    setSearchParams,
  );

  useEffect(() => {
    if (keywordInputValue) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(
        () => setSearchParam("keywords", keywordInputValue),
        800,
      );
      return () => clearTimeout(timeoutRef.current);
    }
    setSearchParam("keywords", keywordInputValue);
  }, [keywordInputValue]);

  return (
    <>
      <div className="flex w-full flex-col-reverse gap-y-2 lg:flex-row lg:items-center lg:justify-between lg:gap-x-4">
        <SearchInput
          placeholder="Search Leads"
          onChange={(e) => setKeywordInputValue(e.target.value)}
          value={keywordInputValue}
          onClear={() => setKeywordInputValue("")}
          className="md:min-w-80"
        />
        {totalLeads && (
          <ExportLeads
            searchParams={searchParams}
            totalLeads={totalLeads}
            isDisabled={isLoading}
          />
        )}
      </div>
      {(!!searchParams.labels.length || !!searchParams.lead_of.length) && (
        <div className="mt-2 flex gap-2.5">
          {searchParams.lead_of.map((param) => (
            <LinkedInAccountsTag
              key={param.name}
              accountIds={[param.name]}
              size="lg"
              rightComponent={
                <button
                  type="button"
                  aria-label={`remove account filter ${param.name}`}
                  onClick={() => toggleSearchParam("lead_of", param)}
                >
                  <X />
                </button>
              }
            />
          ))}
          {searchParams.labels.map((label) => (
            <Tag
              key={label.name}
              variant="primary-black"
              size={isDesktop ? "lg" : "md"}
              rightIcon={
                <button
                  type="button"
                  aria-label={`remove label filter ${label}`}
                  onClick={() => toggleSearchParam("labels", label)}
                >
                  <X />
                </button>
              }
            >
              {label.name}
            </Tag>
          ))}
        </div>
      )}
    </>
  );
}
