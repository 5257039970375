import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { del, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useWorkspaceCacheUpdate from "common/datahooks/useWorkspaceCacheUpdate";
import { PlanId, Workspace } from "common/types";

async function assignLicenseRequest(workspaceId: string, accountId: string) {
  await post(`workspaces/${workspaceId}/accounts/${accountId}/license`);
}
async function removeLicenseRequest(workspaceId: string, accountId: string) {
  await del(`workspaces/${workspaceId}/accounts/${accountId}/license`);
}
async function removeLicenseCancellationRequest(
  workspaceId: string,
  accountId: string,
): Promise<Workspace> {
  return (
    await post(
      `workspaces/${workspaceId}/accounts/${accountId}/license/reactivate`,
    )
  ).workspace;
}
async function setLicenseCountRequest(
  workspaceId: string,
  count: number,
  planId: PlanId,
  accountsToCancel: string[],
): Promise<Workspace> {
  return (
    await post(`workspaces/${workspaceId}/licenses`, {
      count,
      item_price_id: planId,
      account_licenses_to_cancel: accountsToCancel,
    })
  ).workspace;
}

export default function useLicenseMutations() {
  const { id: workspaceId, accounts: workspaceAccounts } =
    useSelectedWorkspaceContext();
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();

  const { mutateAsync: assignLicense, isPending: isAssigningLicense } =
    useMutation({
      mutationFn: ({ accountId }: { accountId: string }) =>
        assignLicenseRequest(workspaceId, accountId),
      onSuccess: (_, { accountId }) => {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          const { licenses, accounts } = draftWorkspace;
          for (let j = 0; j < licenses.length; j += 1) {
            if (!licenses[j].account_id) {
              licenses[j].account_id = accountId;
              break;
            }
          }
          for (let j = 0; j < accounts.length; j += 1) {
            if (accounts[j].id === accountId) {
              accounts[j].license = {
                account_id: accountId,
                status: "active",
                expires_at: 0,
                source: "aimfox",
              };
            }
          }
        });
        const account = workspaceAccounts.find(({ id }) => id === accountId);
        toast.success(`Seat assigned to ${account.full_name}`);
      },
    });

  const { mutateAsync: removeLicense, isPending: isRemovingLicense } =
    useMutation({
      mutationFn: ({ accountId }: { accountId: string }) =>
        removeLicenseRequest(workspaceId, accountId),
      onSuccess: (_, { accountId }) => {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          const { licenses, accounts } = draftWorkspace;
          for (let j = 0; j < licenses.length; j += 1) {
            if (licenses[j].account_id === accountId) {
              licenses[j].account_id = undefined;
            }
          }
          for (let j = 0; j < accounts.length; j += 1) {
            if (accounts[j].id === accountId) {
              delete accounts[j].license;
            }
          }
        });
      },
    });

  const {
    mutateAsync: removeLicenseCancellation,
    isPending: isRemovingLicenseCancellation,
  } = useMutation({
    mutationFn: ({ accountId }: { accountId: string }) =>
      removeLicenseCancellationRequest(workspaceId, accountId),
    onSuccess: (updatedWorkspace, { accountId }) => {
      updateCachedWorkspace(workspaceId, (draftWorkspace) => {
        const { licenses, accounts } = draftWorkspace;
        for (let j = 0; j < licenses.length; j += 1) {
          if (licenses[j].account_id === accountId) {
            licenses[j].expires_at = 0;
            licenses[j].status = "active";
          }
        }
        for (let j = 0; j < accounts.length; j += 1) {
          if (accounts[j].id === accountId) {
            accounts[j].license.expires_at = 0;
            accounts[j].license.status = "active";
          }
        }

        draftWorkspace.active_license_count =
          updatedWorkspace.active_license_count;
      });
    },
  });

  const { mutateAsync: setLicenseCount, isPending: isSettingLicenseCount } =
    useMutation({
      mutationFn: ({
        count,
        planId,
        accountsToCancel,
      }: {
        count: number;
        planId: PlanId;
        accountsToCancel: string[];
      }) =>
        setLicenseCountRequest(workspaceId, count, planId, accountsToCancel),
      onSuccess: (updatedWorkspace) => {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          draftWorkspace.accounts = updatedWorkspace.accounts.map(
            (account) => ({
              ...account,
              license: updatedWorkspace.licenses.find(
                (license) => license.account_id === account.id,
              ),
            }),
          );

          Object.assign(draftWorkspace, {
            licenses: updatedWorkspace.licenses,
            license_count: updatedWorkspace.license_count,
            active_license_count: updatedWorkspace.active_license_count,
          });
        });

        toast.success("Your subscription has been updated!");
      },
    });

  return {
    assignLicense,
    isAssigningLicense,
    removeLicense,
    isRemovingLicense,
    removeLicenseCancellation,
    isRemovingLicenseCancellation,
    setLicenseCount,
    isSettingLicenseCount,
  };
}
