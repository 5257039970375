import React, { JSX } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { clsx } from "clsx";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import ProfileImage, {
  ProfileImageProps,
} from "common/components/ui/ProfileImage";

const variants = cva(
  "flex items-center gap-x-1 text-nowrap rounded-full py-0.5 pl-1 [&_svg]:size-4",
  {
    variants: {
      size: {
        sm: "gap-x-1 text-caption-10-semibold",
        md: "gap-x-1 text-caption-10-semibold",
        lg: "gap-x-1.5 text-button-12",
      },
    },
  },
);
interface LinkedInAccountsLabelProps extends VariantProps<typeof variants> {
  accountIds: string[];
  rightComponent?: JSX.Element;
  isDisabled?: boolean;
}
const profileImageSizes: Record<
  LinkedInAccountsLabelProps["size"],
  ProfileImageProps["size"]
> = {
  sm: "3xs",
  md: "2xs",
  lg: "xs",
};
export default function LinkedInAccountsTag({
  accountIds,
  size = "md",
  rightComponent = null,
  isDisabled = false,
}: LinkedInAccountsLabelProps) {
  const { accounts } = useSelectedWorkspaceContext();

  const label =
    accountIds.length > 1
      ? `${accountIds.length} accounts`
      : accounts.find(({ id }) => id.toString() === accountIds[0].toString())
          .full_name;

  const component = (
    <div
      className={clsx(
        variants({ size }),
        rightComponent ? "pr-1.5" : "pr-3",
        isDisabled ? "bg-black-200" : "bg-purple-50",
      )}
    >
      <div className="flex shrink-0 flex-row -space-x-1.5 rtl:space-x-reverse">
        {accountIds.slice(0, 3).map((selectedAccountId) => {
          const account = accounts.find(
            ({ id }) => id.toString() === selectedAccountId,
          );
          return (
            <ProfileImage
              key={account.id}
              size={profileImageSizes[size]}
              className={clsx(
                "rounded-full ring-1 ring-purple-50",
                isDisabled && "grayscale",
              )}
              src={account.picture_url}
            />
          );
        })}
      </div>
      <span className={clsx("truncate", isDisabled && "text-black-400")}>
        {label}
      </span>
      {rightComponent}
    </div>
  );

  if (accountIds.length > 1) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>{component}</TooltipTrigger>
        <TooltipContent className="flex flex-col gap-x-2.5">
          {accountIds.map((accountId) => {
            const account = accounts.find(
              ({ id }) => id.toString() === accountId,
            );
            return (
              <div key={account.id} className="flex items-center gap-x-1">
                <ProfileImage
                  key={account.id}
                  size="3xs"
                  src={account.picture_url}
                  className="rounded-full"
                />
                <span className="text-caption-10-semibold">
                  {account.full_name}
                </span>
              </div>
            );
          })}
        </TooltipContent>
      </Tooltip>
    );
  }
  return component;
}
