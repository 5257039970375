import React from "react";
import { ArrowRight, CheckCircle } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";

import AimfoxLogoOrange from "assets/aimfoxLogos/aimfox-logo-orange.svg?react";

const features = [
  "Bypass LinkedIn connection request limits",
  "AI-optimized outreach automation",
  "Built-in campaign builder",
  "Follow-up direct message sequences",
  "Various performance optimization options",
  "Auto-translate campaigns and DMs",
];

export default function FreeTrial({ onNext }: { onNext: () => void }) {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-1 flex-col items-center md:items-start md:pl-4 md:pr-8">
        <div className="mb-8 flex items-end max-md:hidden">
          <AimfoxLogoOrange className="w-9" />
          <span className="text-headline-xl-bold">Aimfox</span>
        </div>

        <h3 className="mb-3 text-headline-2xl-bold max-md:text-center md:text-headline-3xl-bold">
          Start your 14-day free trial
        </h3>
        <p className="mb-8 text-black-500 max-md:text-center md:text-body-16-regular">
          Test out LinkedIn Outreach completely free of charge with unlimited
          campaigns, unlimited seats and the full power of Aimfox
        </p>

        <Button
          size="lg"
          className="max-md:w-full md:w-[238px]"
          onClick={onNext}
          rightIcon={<ArrowRight />}
        >
          Lets go
        </Button>
      </div>

      <div className="flex-1 bg-[url(/images/free-trial-bg.png)] bg-cover bg-right text-white max-md:mt-4 max-md:rounded-2xl max-md:p-4 md:-mb-6 md:-mr-4 md:-mt-10 md:rounded-r-2xl md:px-8 md:pb-6 md:pt-10">
        <h3 className="mb-4 text-headline-xl-bold">
          Automate your LinkedIn 10x better with Aimfox linkedin outreach tool
        </h3>
        <p className="mb-6 text-body-16-regular">
          With Aimfox, get the best results and have great user experience
          working flow
        </p>

        <p className="text-black-200">
          Promote your LinkedIn account like never before. Grow your LinkedIn
          network:
        </p>

        <ul className="my-3 flex flex-col gap-y-2">
          {features.map((feature) => (
            <li key={feature} className="flex items-center gap-x-2">
              <CheckCircle weight="fill" size={16} />
              <span className="max-md:text-caption-12-regular">{feature}</span>
            </li>
          ))}
        </ul>

        <p className="text-black-200">
          With fully automated, personalized campaigns that convert. And a
          sprinkle of AI.
        </p>
      </div>
    </div>
  );
}
