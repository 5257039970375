import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { del, patch, post } from "../helpers/HTTP";
import {
  PlanId,
  Subscription,
  SubscriptionSettings,
  Workspace,
} from "../types";
import useWorkspaceCacheUpdate from "./useWorkspaceCacheUpdate";

async function createSubscriptionRequest(
  workspaceId: string,
  planId: PlanId,
  licenseCount: number,
  paymentSourceId: string,
): Promise<Subscription> {
  return (
    await post(`workspaces/${workspaceId}/subscriptions`, {
      item_price_id: planId,
      licenses: licenseCount,
      payment_source_id: paymentSourceId,
    })
  ).subscription;
}

async function addCouponRequest(
  subscriptionId: string,
  coupon: string,
): Promise<SubscriptionSettings> {
  return (
    await patch(`subscriptions/${subscriptionId}/coupon`, {
      coupon,
    })
  ).subscription;
}

async function removeChangeRequest(workspaceId: string): Promise<Workspace> {
  return (await del(`workspaces/${workspaceId}/changes`)).workspace;
}

export default function useSubscriptionMutations() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();

  const { mutateAsync: createSubscription, isPending: isCreatingSubscription } =
    useMutation({
      mutationFn: ({
        planId,
        licenseCount,
        paymentSourceId,
      }: {
        planId: PlanId;
        licenseCount: number;
        paymentSourceId: string;
      }) =>
        createSubscriptionRequest(
          workspaceId,
          planId,
          licenseCount,
          paymentSourceId,
        ),
      onSuccess: (newSubscription, { licenseCount }) => {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          draftWorkspace.subscription = newSubscription;
          draftWorkspace.license_count = licenseCount;
          draftWorkspace.active_license_count = licenseCount;
        });

        toast.success("Subscription created");
      },
    });

  const { mutateAsync: addCoupon, isPending: isAddingCoupon } = useMutation({
    mutationFn: ({
      subscriptionId,
      coupon,
    }: {
      subscriptionId: string;
      coupon: string;
    }) => addCouponRequest(subscriptionId, coupon),
    onSuccess: (subscriptionSettings) =>
      queryClient.setQueryData(
        ["subscriptionSettings", subscriptionSettings.id],
        subscriptionSettings,
      ),
  });

  const { mutateAsync: removeChange, isPending: isRemovingChange } =
    useMutation({
      mutationFn: () => removeChangeRequest(workspaceId),
      onSuccess: (updatedWorkspace) => {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          draftWorkspace.accounts = updatedWorkspace.accounts.map(
            (account) => ({
              ...account,
              license: updatedWorkspace.licenses.find(
                (license) => license.account_id === account.id,
              ),
            }),
          );

          Object.assign(draftWorkspace, {
            licenses: updatedWorkspace.licenses,
            license_count: updatedWorkspace.license_count,
            active_license_count: updatedWorkspace.active_license_count,
          });
        });

        toast.success("Scheduled change removed");
      },
    });

  return {
    createSubscription,
    isCreatingSubscription,

    removeChange,
    isRemovingChange,

    addCoupon,
    isAddingCoupon,
  };
}
