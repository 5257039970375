import React from "react";

import RenderIf from "common/components/RenderIf";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  formatPrice,
  formatPeriodUnitLabel,
  formatShortDate,
} from "common/helpers/utils";
import Skeleton from "common/components/ui/Skeleton";
import usePricing from "common/datahooks/usePricing";
import { PlanId } from "common/types";

import Banner from "./Banner";
import ToPayNow from "./ToPayNow";
import CurrentLicense from "./CurrentLicense";
import NewPrice from "./NewPrice";
import FreeTrialBanner from "./FreeTrialBanner";

interface PricingInfoProps {
  licenseCount: number;
  selectedPlan: PlanId;
}

export default function PricingInfo({
  licenseCount,
  selectedPlan,
}: PricingInfoProps) {
  const { pricing, isLoadingPricing } = usePricing();
  const { license_count: workspaceLicenseCount, subscription } =
    useSelectedWorkspaceContext();

  const licensePricePerSeat = pricing?.find(({ id }) => {
    if (selectedPlan === "Basic-USD-Monthly") {
      return id === "Outreach-License-USD-Monthly";
    }

    return id === "Outreach-License-USD-Yearly";
  }).price;

  let bannerComponent;

  const isDowngrade = licenseCount < workspaceLicenseCount;
  const isUpgradeToYearly =
    subscription?.pricing_id === "Basic-USD-Monthly" &&
    selectedPlan === "Basic-USD-Yearly";

  if (isUpgradeToYearly) {
    bannerComponent = (
      <Banner
        variant="info"
        textNode="Switching your subscription to yearly billing is effective for all current and future seat purchases"
      />
    );
  } else if (!subscription && selectedPlan === "Basic-USD-Yearly") {
    bannerComponent = (
      <Banner
        textNode="Yearly plans are paid up-front and do not offer a free trial"
        variant="info"
      />
    );
  } else if (isDowngrade) {
    bannerComponent = (
      <Banner
        textNode="This is a scheduled change. Your subscription will not change until your next billing date"
        variant="warning"
      />
    );
  } else if (!subscription) {
    bannerComponent = (
      <FreeTrialBanner
        licenseCount={licenseCount}
        selectedPlan={selectedPlan}
      />
    );
  } else if (subscription.status === "in_trial") {
    const nextBillingDate = formatShortDate(subscription.next_billing * 1000);

    bannerComponent = (
      <Banner
        textNode={`You will not be charged until your  trial expires. Your subscription will automatically upgrade to a paid plan on ${nextBillingDate}`}
        variant="gradient"
      />
    );
  }

  return (
    <section className="flex flex-col gap-y-2.5">
      <div className="flex items-center justify-between rounded-md bg-black-100 px-2 py-1">
        <span className="text-button-14 text-black-600">Price per seat</span>
        {isLoadingPricing ? (
          <Skeleton className="h-6 w-14" />
        ) : (
          <span className="text-body-16-regular text-black-500">
            {formatPrice(licensePricePerSeat / 100)}/
            {formatPeriodUnitLabel(selectedPlan)}
          </span>
        )}
      </div>

      {/* Current price & Number of licenses */}
      <RenderIf condition={subscription && subscription.status !== "cancelled"}>
        <CurrentLicense />
      </RenderIf>

      <div className="flex items-center justify-between px-2 py-1">
        <span className="text-button-14 text-black-600">New no. of seats</span>
        <span className="text-body-16-bold">{licenseCount}</span>
      </div>

      <NewPrice licenseCount={licenseCount} selectedPlan={selectedPlan} />

      {bannerComponent || <span className="h-px w-full bg-black-200" />}

      <ToPayNow licenseCount={licenseCount} selectedPlan={selectedPlan} />
    </section>
  );
}
