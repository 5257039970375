import React, { useState } from "react";
import { LinkedinLogo, Users } from "@phosphor-icons/react";

import { getProfileSubtitle } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ProfileImage from "common/components/ui/ProfileImage";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import RenderIf from "common/components/RenderIf";
// import { Lead } from "common/types";

import GroupConversationAvatar from "../../GroupConversationAvatar";
import { Conversation } from "../../../types";
import { getGroupParticipantsString } from "../../../utils";

interface ParticipantsProfilesProps {
  conversation: Conversation;
  // openLeadsPreview: (lead: Lead) => void;
}

export default function ParticipantsProfiles({
  conversation,
  // openLeadsPreview,
}: ParticipantsProfilesProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isDesktop = useTwBreakpoint("lg");
  const isLargeDesktop = useTwBreakpoint("xl");

  const { participants } = conversation;

  const isGroup = participants.length > 1;
  const groupName = conversation
    ? getGroupParticipantsString(conversation)
    : null;

  const Component = isDesktop ? Dialog : Drawer;
  const Trigger = isDesktop ? DrawerTrigger : DialogTrigger;
  const Content = isDesktop ? DialogContent : DrawerContent;

  if (isGroup) {
    return (
      <Component open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Trigger asChild>
          <Button
            intent={isLargeDesktop ? "default" : "iconOnly"}
            variant="secondary-purple"
          >
            <Users />
            <RenderIf condition={isLargeDesktop}>Members</RenderIf>
          </Button>
        </Trigger>

        <Content className="flex flex-col bg-black-100 lg:max-w-[512px] lg:bg-white lg:p-4">
          <div className="mb-4 block rounded-2xl bg-white px-3 py-4 lg:hidden">
            {/* Conversation info */}
            <div className="flex flex-col items-center gap-3">
              {groupName ? (
                <GroupConversationAvatar participants={participants} />
              ) : (
                <ProfileImage size="lg" src={participants[0].picture_url} />
              )}

              <div className="ml-2 flex flex-col items-center justify-center text-start">
                <span className="mb-0.5 line-clamp-1 max-w-[40ch] text-button-16">
                  {groupName || participants[0].full_name}
                </span>

                <p className="line-clamp-1 flex max-w-[275px] flex-row flex-wrap items-center gap-1 text-caption-12-regular text-purple-600">
                  {groupName
                    ? `${participants.length} members`
                    : getProfileSubtitle(participants[0])}
                </p>
              </div>
            </div>
          </div>

          {/* Group participants info */}
          <div className="rounded-2xl bg-white px-3 py-4 lg:bg-transparent lg:p-0">
            <h2 className="mb-2.5 text-button-14 lg:text-headline-2xl-bold">
              Members
            </h2>

            {participants.map((participant) => {
              const {
                id,
                picture_url: pictureUrl,
                full_name: fullName,
                public_identifier: publicIdentifier,
              } = participant;

              return (
                <div
                  key={id}
                  className="flex flex-row justify-between border-black-200 py-2 lg:border-none [&:not(:last-child)]:border-b"
                >
                  <div className="flex flex-row items-center gap-1">
                    <ProfileImage size="sm" src={pictureUrl} />

                    <div className="ml-2 flex flex-col items-start justify-center text-start">
                      <span className="line-clamp-1 max-w-[40ch] text-button-14">
                        {fullName}
                      </span>

                      <span className="line-clamp-1 max-w-[296px] text-caption-12-regular text-black-600">
                        {getProfileSubtitle(participant)}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <Button variant="secondary-black" intent="iconOnly" asChild>
                      <a
                        href={`https://linkedin.com/in/${publicIdentifier}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="LinkedIn Profile URL"
                      >
                        <LinkedinLogo weight="fill" />
                      </a>
                    </Button>

                    {/* <Button
                      variant="secondary-purple"
                      intent="iconOnly"
                      onClick={() => {
                        setIsDialogOpen(false);
                        openLeadsPreview(participant);
                      }}
                    >
                      <User />
                    </Button> */}
                  </div>
                </div>
              );
            })}
          </div>
        </Content>
      </Component>
    );
  }

  // TODO: Temporarily disable this feature until Inbox Node is completed & released
  // Current functionality does not support his

  return null;

  // return (
  //   <Button
  //     variant="secondary-purple"
  //     intent={isLargeDesktop ? "default" : "iconOnly"}
  //     onClick={() => openLeadsPreview(participants[0])}
  //   >
  //     <User />
  //     <RenderIf condition={isLargeDesktop}>Profile Preview</RenderIf>
  //   </Button>
  // );
}
