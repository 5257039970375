import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import { useIntercom } from "react-use-intercom";
import Hotjar from "@hotjar/browser";

import useGTM from "common/hooks/useGTM";
import { isProductionEnvironment } from "common/helpers/utils";
import checkAuth from "common/helpers/checkAuth";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import WorkspaceLayout from "common/layouts/WorkspaceLayout";
import BaseLayout from "common/layouts/BaseLayout";
import useWorkspaces from "common/datahooks/useWorkspaces";

import useCustomer from "./common/datahooks/useCustomer";
import useCustomerIO from "./common/hooks/useCustomerIO";
import CrashPage from "./common/components/CrashPage";
import { baseRoutes, workspaceRoutes } from "./pages/routes";
import Onboarding from "./pages/onboarding/components/Onboarding";

function App() {
  const isDesktop = useTwBreakpoint("lg");
  const signupRef = useRef(false);
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const { customerInfo } = useCustomer();
  const { workspaces } = useWorkspaces();
  const { initializeCustomerIO } = useCustomerIO();
  const { sendEvent } = useGTM();
  const { boot } = useIntercom();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (customerInfo && isAuthLoaded) {
      boot({
        email: customerInfo.email,
        name: `${customerInfo.first_name} ${customerInfo.last_name}`,
        phone: customerInfo.phone,
        hideDefaultLauncher:
          !isDesktop || import.meta.env.MODE === "development",
      });
      initializeCustomerIO(customerInfo.chargebee_id, customerInfo.email);
      if (isProductionEnvironment()) {
        Sentry.setTag("keycloak id", customerInfo.id);
        Sentry.setUser({
          id: customerInfo.id,
          email: customerInfo.email,
          first_name: customerInfo.first_name,
          last_name: customerInfo.last_name,
        });
        Hotjar.identify(customerInfo.id, {
          email: customerInfo.email,
          name: `${customerInfo.first_name} ${customerInfo.last_name}`,
        });
      }
    }
  }, [isAuthLoaded, customerInfo]);

  useEffect(() => {
    if (searchParams.get("signup")) {
      signupRef.current = true;
    }
    checkAuth().then(() => {
      setIsAuthLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (customerInfo && signupRef.current) {
      sendEvent("signed_up", ({ email }) => ({ email }));
    }
  }, [customerInfo]);

  useEffect(() => {
    if (workspaces && customerInfo) {
      document.getElementById("loader-container")?.remove();
    }
  }, [workspaces, customerInfo]);

  if (!workspaces || !customerInfo) {
    return null;
  }

  return (
    <ErrorBoundary
      /* eslint-disable-next-line react/no-unstable-nested-components */
      fallback={({ error, resetError, eventId, componentStack }) => (
        <CrashPage
          error={error}
          resetError={resetError}
          eventId={eventId}
          componentStack={componentStack}
        />
      )}
    >
      <Routes>
        {workspaces.length ? (
          <>
            {/* Base Routes */}
            <Route element={<BaseLayout />}>
              {baseRoutes.map(({ component, path }) => (
                <Route key={path} path={path} element={component} />
              ))}
            </Route>

            {/* Workspace routes */}
            <Route path="workspaces/:workspaceId" element={<WorkspaceLayout />}>
              {workspaceRoutes.map(({ path, component }) => (
                <Route key={path} path={path} element={component} />
              ))}
            </Route>

            {/* TODO: Store last visited workspace in Local Storage */}
            <Route
              path="*"
              element={
                <Navigate
                  to={`workspaces/${workspaces[0].id}/${workspaceRoutes[0].path}?${searchParams.toString()}`}
                  replace
                />
              }
            />
          </>
        ) : (
          <>
            <Route path="/onboarding" element={<Onboarding />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={`onboarding?${searchParams.toString()}`}
                  replace
                />
              }
            />
          </>
        )}
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
