import React from "react";
import { Export } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import { LinkedInProfile } from "common/types";
import RenderIf from "common/components/RenderIf";
import CSVFileUpload from "common/components/CSVFileUpload";
import useLinkedInUrls from "common/hooks/useLinkedInUrls";

import useBlacklistMutations from "../../datahooks/useBlacklistMutations";

const maxUrls = 1000;
export default function CsvUpload({
  failedUrls,
  onGetProfiles,
}: {
  failedUrls: string[] | null;
  onGetProfiles: (profiles: LinkedInProfile[], failed: string[]) => void;
}) {
  const { linkedInUrls, setLinkedInUrls, validUrls, invalidUrls } =
    useLinkedInUrls();
  const { getProfiles, isGettingProfiles } = useBlacklistMutations();

  function onNext() {
    getProfiles({
      identifiers: linkedInUrls.map((url) => url.split("/")[4]),
    }).then(({ profiles, failed }) => {
      onGetProfiles(profiles, failed);
    });
  }

  const isEmpty = !validUrls.length;
  let errorLines = [];
  if (invalidUrls.length) {
    errorLines = invalidUrls;
  } else if (failedUrls) {
    errorLines = failedUrls.map((value, index) => index + 1);
  }

  return (
    <>
      <CSVFileUpload
        lineItems={failedUrls || linkedInUrls}
        setLineItems={setLinkedInUrls}
        errorLines={errorLines}
        maxAllowedItems={maxUrls}
        footerContent={
          failedUrls && (
            <Button
              className="ml-auto mt-2"
              variant="secondary-purple"
              leftIcon={<Export />}
              asChild
            >
              <a
                href={`data:text/csv;charset=utf-8,${failedUrls.join(",")}`}
                download="failed_links.csv"
              >
                Export ({failedUrls.length})
              </a>
            </Button>
          )
        }
      />
      <RenderIf condition={!failedUrls}>
        <div className="mt-4 flex justify-end border-t border-t-black-200 pt-4 lg:mt-auto">
          <Button
            size="lg"
            disabled={
              isEmpty || invalidUrls.length || validUrls.length > maxUrls
            }
            isLoading={isGettingProfiles}
            onClick={onNext}
          >
            Next
          </Button>
        </div>
      </RenderIf>
    </>
  );
}
