import React from "react";
import { PlusCircle } from "@phosphor-icons/react";

import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import CreateNewCampaign from "common/components/CreateNewCampaign";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import RestrictedComponent from "common/components/RestrictedComponent";

import NoCampaignsImage from "assets/images/empty-placeholders/no-campaigns.png";

export default function NoCampaignsPlaceholder() {
  const workspace = useSelectedWorkspaceContext();
  const hasAccount = !!workspace.accounts.length;

  return (
    <EmptyPlaceholder
      title={
        hasAccount
          ? "No campaigns found"
          : "Add a LinkedIn account and create your first campaign"
      }
      subtitle={
        hasAccount
          ? "This screen shows data from your outreach campaigns. Create a campaign and start growing your LinkedIn network"
          : "Start getting leads with Aimfox - Connect your LinkedIn account to build your first outreach campaign"
      }
      imageSrc={NoCampaignsImage}
      actionComponent={
        <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
          {(isDisabled) => (
            <CreateNewCampaign
              triggerButton={
                <Button
                  leftIcon={<PlusCircle weight="fill" />}
                  disabled={isDisabled}
                >
                  {hasAccount ? "Create campaign" : "Get started"}
                </Button>
              }
            />
          )}
        </RestrictedComponent>
      }
    />
  );
}
