import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Label } from "common/types";

import getQueryKeys from "./getQueryKeys";

async function getLabels(workspaceId: string) {
  return (await get(`workspaces/${workspaceId}/labels`)).labels;
}

export default function useAccountLabels() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { labelsKeys } = getQueryKeys(workspaceId);

  const { data: accountLabels } = useQuery<Label[]>({
    queryKey: labelsKeys.list(),
    queryFn: () => getLabels(workspaceId),
  });

  return {
    accountLabels,
  };
}
