import React from "react";

import useAppStore from "common/hooks/useAppStore";
import Checkbox from "common/components/ui/Checkbox";
import Label from "common/components/ui/Label";

export default function MiscOptions() {
  const shouldDebugLogin = useAppStore(
    (state) => state.debugOptions.shouldDebugLogin,
  );
  const isPaymentMethodHidden = useAppStore(
    (state) => state.debugOptions.isPaymentMethodHidden,
  );
  const isWithoutSubscription = useAppStore(
    (state) => state.debugOptions.isWithoutSubscription,
  );

  return (
    <>
      <span className="my-2 text-caption-12-regular text-black-400">
        Misc. Options
      </span>
      <div className="flex flex-row items-center gap-2">
        <Checkbox
          id="debugLogin"
          size="sm"
          checked={shouldDebugLogin}
          onChange={({ target: { checked } }) =>
            useAppStore
              .getState()
              .setDebugOptions({ shouldDebugLogin: checked })
          }
        />
        <Label htmlFor="debugLogin" size="sm">
          Debug Login
        </Label>
      </div>
      <div className="flex flex-row items-center gap-2">
        <Checkbox
          id="paymentMethodHidden"
          size="sm"
          checked={isPaymentMethodHidden}
          onChange={({ target: { checked } }) =>
            useAppStore
              .getState()
              .setDebugOptions({ isPaymentMethodHidden: checked })
          }
        />
        <Label htmlFor="paymentMethodHidden" size="sm">
          No Payment method
        </Label>
      </div>
      <div className="flex flex-row items-center gap-2">
        <Checkbox
          id="subscriptionHidden"
          size="sm"
          checked={isWithoutSubscription}
          onChange={({ target: { checked } }) =>
            useAppStore
              .getState()
              .setDebugOptions({
                isWithoutSubscription: checked,
                subscriptionStatus: null,
              })
          }
        />
        <Label htmlFor="subscriptionHidden" size="sm">
          No Subscription
        </Label>
      </div>
    </>
  );
}
