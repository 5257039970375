import React, { useState } from "react";
import clsx from "clsx";
import { Sparkle } from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { TemplateType } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import Switch from "common/components/ui/Switch";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { useCampaignContext } from "common/helpers/CampaignContext";

import useFlowTemplatePreview from "../../../../datahooks/useFlowTemplatePreview";
import PreviewContent from "./PreviewContent";
import useTargets from "../../../../datahooks/useTargets";
import { useCampaignFlowContext } from "../../../../context/CampaignFlowContext";
import { Target } from "../../../../types";
import PreviewHeader from "./PreviewHeader";

interface FlowTemplatePreviewProps {
  isAiEnabled: boolean;
  templateType: TemplateType;
  message: string;
  subject?: string;
}

export default function FlowTemplatePreview({
  isAiEnabled,
  templateType,
  message,
  subject = null,
}: FlowTemplatePreviewProps) {
  const isDesktop = useTwBreakpoint("lg");
  const [shouldShowTranslatedText, setShouldShowTranslatedText] =
    useState(false);
  const { flow } = useCampaignFlowContext();
  const [currentTarget, setCurrentTarget] = useState(0);
  const { targets, isLoadingTargets } = useTargets();
  const { accounts } = useSelectedWorkspaceContext();
  const { accountId } = useCampaignContext();

  const account = accounts.find(({ id }) => id === accountId);

  let selectedTarget: Target;
  let targetsForPreview: Target[] = [];
  if (!isLoadingTargets) {
    targetsForPreview = targets.slice(0, 3);
    selectedTarget = targetsForPreview[currentTarget];
  }

  const handlePrevClick = () => {
    if (currentTarget === 0) {
      setCurrentTarget(targetsForPreview.length - 1);
    } else {
      setShouldShowTranslatedText(false);
      setCurrentTarget(currentTarget - 1);
    }
  };
  const handleNextClick = () => {
    if (currentTarget === targetsForPreview.length - 1) {
      setCurrentTarget(0);
    } else {
      setShouldShowTranslatedText(false);
      setCurrentTarget(currentTarget + 1);
    }
  };

  const {
    aiContent,
    injectContent,
    translateContent,
    refetchPreview,
    isPreviewError,
    isLoadingPreview,
  } = useFlowTemplatePreview({
    templateContent: { message, subject },
    isAiEnabled,
    isTranslateEnabled: flow.translate,
    leadId: !isLoadingTargets && selectedTarget.id,
    type: templateType,
    languageCode: flow.source_language,
    disableQuery: isLoadingTargets,
  });

  const content = {
    displayMessage: "",
    displaySubject: "",
  };

  if (flow.translate && translateContent && shouldShowTranslatedText) {
    content.displayMessage = translateContent.message;
    content.displaySubject = translateContent.subject;
  } else if (isAiEnabled && aiContent) {
    content.displayMessage = aiContent.message;
    content.displaySubject = aiContent.subject;
  } else if (injectContent) {
    content.displayMessage = injectContent.message;
    content.displaySubject = injectContent.subject;
  }

  const showTranslatedTextComponent = (
    <RenderIf condition={flow.translate && !!message}>
      <div className="flex items-center gap-2 max-lg:justify-between max-lg:p-3 max-lg:pb-1">
        <label
          htmlFor="showTranslatedTextToggle"
          className={clsx(
            "cursor-pointer transition-colors",
            !shouldShowTranslatedText && "text-black-400",
          )}
        >
          Show translated
        </label>
        <Switch
          id="showTranslatedTextToggle"
          checked={shouldShowTranslatedText}
          onCheckedChange={setShouldShowTranslatedText}
        />
      </div>
    </RenderIf>
  );

  return (
    <section
      className={clsx(
        "flex min-h-80 flex-1 flex-col overflow-hidden rounded-2xl border border-black-200",
        isAiEnabled && "bg-lightPurple",
      )}
    >
      <PreviewHeader
        isLoading={isLoadingTargets}
        currentTarget={currentTarget}
        targetsLength={targetsForPreview.length}
        selectedTarget={selectedTarget}
        handleNextClick={handleNextClick}
        handlePrevClick={handlePrevClick}
      />
      {!isDesktop && showTranslatedTextComponent}
      <div className="flex flex-1 flex-col gap-3 p-3">
        <div className="flex w-full items-center gap-2">
          <ProfileImage src={account.picture_url} size="sm" />
          <span className="flex-1 text-button-16">
            {account.full_name} (You)
          </span>
          {isDesktop && showTranslatedTextComponent}
        </div>

        <PreviewContent
          message={content.displayMessage}
          subject={subject && content.displaySubject}
          isLoading={isLoadingPreview}
          onTryAgain={isPreviewError && refetchPreview}
        />

        <RenderIf condition={isAiEnabled && isDesktop}>
          <Tag
            leftIcon={<Sparkle />}
            variant="gradient"
            className="ml-auto mt-auto"
          >
            AI enhanced
          </Tag>
        </RenderIf>
      </div>
    </section>
  );
}
