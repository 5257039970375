import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { get, post } from "../helpers/HTTP";

async function resetUnreadMessagesRequest(
  workspaceId: string,
  versionTag: string,
) {
  await post(
    `workspaces/${workspaceId}/conversations/unread?version_tag=${versionTag}`,
  );
}

async function getUnreadMessagesCount(workspaceId: string) {
  const { version_tag: versionTag, unread_count: unreadMessagesCount } =
    await get(`workspaces/${workspaceId}/conversations/unread`);
  return { versionTag, unreadMessagesCount };
}

export default function useUnreadMessagesCount({
  disableQuery,
}: { disableQuery?: boolean } = {}) {
  const queryKey = ["unreadMessages"];
  const queryClient = useQueryClient();
  const { id: workspaceId, accounts } = useSelectedWorkspaceContext();

  const { data } = useQuery<{
    unreadMessagesCount: number;
    versionTag: string;
  }>({
    queryKey,
    queryFn: () => getUnreadMessagesCount(workspaceId),
    refetchInterval: 1000,
    enabled: !disableQuery && !!accounts.length,
    refetchOnMount: false,
  });

  const { mutate: resetUnreadMessages } = useMutation({
    mutationFn: ({ versionTag }: { versionTag: string }) =>
      resetUnreadMessagesRequest(workspaceId, versionTag),
    onSuccess: () => {
      queryClient.setQueryData<{
        unreadMessagesCount: number;
        versionTag: string;
      }>(queryKey, (prevValue) => ({
        ...prevValue,
        unreadMessagesCount: 0,
      }));
    },
  });

  return {
    ...data,
    resetUnreadMessages,
  };
}
