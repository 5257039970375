import languages from "common/constants/languages";
import { TimezoneOption, timezones } from "common/constants/timezones";
import { Schedule, ScheduleInterval } from "common/types";

import { CampaignFlow, CampaignFlowType, Timezone } from "./types";

export function getLanguageName(code: string) {
  return languages.find((lang) => lang.code === code)?.name;
}

export function formattedTimezone(timezone: Timezone): TimezoneOption {
  if (!timezone.name) {
    return timezones[0];
  }

  return timezones.find(
    (timezoneOption) => timezoneOption.label === timezone.name,
  );
}

export function getFlowsByType(flows: CampaignFlow[]) {
  const primaryFlows = flows.filter(
    (flow) =>
      flow.type === "PRIMARY_CONNECT" || flow.type === "PRIMARY_MESSAGE",
  );

  const connectFlow: CampaignFlow = null;

  const inmailFlow = flows.find((flow) => flow.type === "INMAIL_OPTIMIZATION");

  return { primaryFlows, connectFlow, inmailFlow };
}

export function getSortedAndFilteredFlows(
  flows: CampaignFlow[],
  isInMailOptimizationEnabled: boolean,
): CampaignFlow[] {
  if (!flows) return;

  const filteredFlows = flows.filter((flow) => {
    if (!isInMailOptimizationEnabled && flow.type === "INMAIL_OPTIMIZATION") {
      return false;
    }
    return true;
  });

  const { primaryFlows, connectFlow, inmailFlow } =
    getFlowsByType(filteredFlows);

  const sortedAndFilteredFlows = [
    ...primaryFlows,
    connectFlow,
    inmailFlow,
  ].filter((flow) => flow);

  return sortedAndFilteredFlows;
}

export function validateFlows(
  flows: CampaignFlow[],
  isInMailOptimizationEnabled: boolean,
) {
  const flowsWithError: CampaignFlow[] = [];
  const sortedAndFilteredFlows = getSortedAndFilteredFlows(
    flows,
    isInMailOptimizationEnabled,
  );

  sortedAndFilteredFlows.forEach((flow) => {
    if (
      (flow.type === "INMAIL_OPTIMIZATION" && !flow.template?.original_id) ||
      (flow.type === "PRIMARY_MESSAGE" &&
        !flow.flow_message_templates.some((message) => message.original_id))
    ) {
      flowsWithError.push(flow);
    }
  });

  const isFlowListValid = !flowsWithError.length;

  return { flowsWithError, isFlowListValid };
}

export function isValidSchedule(schedule: Schedule) {
  return Object.values(schedule).some(({ intervals }) => intervals.length > 0);
}

export function getFormattedInterval({ start, end }: ScheduleInterval) {
  const startValue = `${start <= 9 ? `0${start}` : start}:00`;

  let endValue;
  if (end === 24) {
    endValue = "23:59";
  } else {
    endValue = `${end <= 9 ? `0${end}` : end}:00`;
  }

  return { startValue, endValue };
}

export function getFlowTitle(flow: CampaignFlow) {
  const flowHeadingOptions: Record<CampaignFlowType, string> = {
    CONNECT_OPTIMIZATION: "Connect Optimization",
    INMAIL_OPTIMIZATION: "Free InMail",
    PRIMARY_MESSAGE: flow.name,
    PRIMARY_CONNECT: flow.name,
  };

  return flowHeadingOptions[flow.type];
}
