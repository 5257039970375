import {
  SchemaWithPipe,
  BaseIssue,
  PipeItem,
  ErrorMessage,
  MaxLengthIssue,
  MaxLengthAction,
  StringSchema,
} from "valibot";
import { ClassValue, clsx } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";
import resolveConfig from "tailwindcss/resolveConfig";

import { LinkedInProfile, PlanId } from "common/types";

import tailwindConfig from "../../../tailwind.config";

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];
export function typedObjectEntries<T extends object>(t: T): Entries<T>[] {
  return Object.entries(t) as Entries<T>[];
}
export function typedObjectKeys<T extends object>(t: T): Array<keyof T> {
  return Object.keys(t) as Array<keyof T>;
}
export function getDayName(dayNumber: number) {
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return dayNames[dayNumber];
}
export function getMonthName(monthNumber: number, shorten: boolean = false) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return shorten
    ? monthNames[monthNumber].slice(0, 3)
    : monthNames[monthNumber];
}
export function addNumberOrdinal(number: number) {
  const numberAsString = number.toString();
  const lastNumber = Number(numberAsString[numberAsString.length - 1]);
  let ordinal = "th";
  if (lastNumber === 1) {
    ordinal = "st";
  } else if (lastNumber === 2) {
    ordinal = "nd";
  } else if (lastNumber === 3) {
    ordinal = "rd";
  }

  return `${number}${ordinal}`;
}
export function timeSince(unixMilliseconds: number) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = Date.now() - unixMilliseconds;

  if (elapsed < msPerMinute) {
    return "Just now";
  }
  if (elapsed < msPerHour) {
    const time = Math.round(elapsed / msPerMinute);
    return `${time} ${time > 1 ? "minutes" : "minute"} ago`;
  }
  if (elapsed < msPerDay) {
    const time = Math.round(elapsed / msPerHour);
    return `${time} ${time > 1 ? "hours" : "hour"} ago`;
  }
  if (elapsed < msPerMonth) {
    const time = Math.round(elapsed / msPerDay);
    return `${time} ${time > 1 ? "days" : "day"} ago`;
  }
  if (elapsed < msPerYear) {
    const time = Math.round(elapsed / msPerMonth);
    return `${time} ${time > 1 ? "months" : "month"} ago`;
  }
  const time = Math.round(elapsed / msPerYear);
  return `${time} ${time > 1 ? "years" : "year"} ago`;
}
export function formatTime(unixMilliseconds: number) {
  const date = new Date(unixMilliseconds);
  let hours: string | number = date.getHours();
  let minutes: string | number = date.getMinutes();

  hours = hours > 9 ? hours : `0${hours}`;
  minutes = minutes > 9 ? minutes : `0${minutes}`;

  return `${hours}:${minutes}`;
}
export function formatTimestamp(unixMilliseconds: number) {
  const date = new Date(unixMilliseconds);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let timePostfix = "am";
  if (hours > 12) {
    hours -= 12;
    timePostfix = "pm";
  }
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  } ${timePostfix}`;
}
export function formatDate(unixMilliseconds: number) {
  const dateObject = new Date(unixMilliseconds);
  const date = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  return `${date > 9 ? date : `0${date}`}.${
    month > 9 ? month : `0${month}`
  }.${dateObject.getFullYear()}`;
}
/* 0 -> 9,999 -- 6754
10,000 -> 99,999 -- 57.2k
100,000 -> 999,999 -- 320k
1,000,000 -> 9,999,999 -- 2.35M
10,000,000 -> 99,999,999 -- 22.5M
100,000,000+ -- 212M */
export function formatNumber(number: number) {
  if (number < 10000) {
    return number.toString();
  }
  if (number < 1000000) {
    return `${Number((number / 1000).toFixed(2))}k`;
  }
  return `${Number((number / 1000000).toFixed(2))}M`;
}
// returns location | occupation or occupation if location is not defined
export function getProfileSubtitle(
  profile: Pick<LinkedInProfile, "location" | "occupation">,
) {
  if (profile.location) {
    return `${profile.location.name} | ${profile.occupation}`;
  }
  return profile.occupation;
}
export function formatLastUpdateAt(unixTimestamp: number) {
  if (Date.now() - unixTimestamp < 60 * 1000) {
    return "just now";
  }
  const today = new Date();
  today.setHours(0, 0, 0);

  if (unixTimestamp > today.getTime()) {
    return formatTimestamp(unixTimestamp);
  }
  const date = new Date(unixTimestamp);
  return `${getMonthName(date.getMonth(), true)} ${date.getDate()}`;
}
export function getSchemaMaxLength(
  schema: SchemaWithPipe<
    [StringSchema<string>, ...PipeItem<unknown, unknown, BaseIssue<unknown>>[]]
  >,
) {
  for (let i = 0; i < schema.pipe.length; i += 1) {
    const validation = schema.pipe[i];
    if (validation.type === "max_length") {
      return (
        validation as MaxLengthAction<
          string,
          number,
          ErrorMessage<MaxLengthIssue<string, number>>
        >
      ).requirement;
    }
  }
  throw Error(`No max_length rule found in schema: ${schema}`);
}
// returns false for preview and development environments
export function isProductionEnvironment() {
  return ["production", "staging"].includes(import.meta.env.MODE);
}
export const tailwindTheme = resolveConfig(tailwindConfig).theme;

const twMerge = extendTailwindMerge({
  override: {
    classGroups: {
      "font-size": typedObjectKeys(tailwindTheme.fontSize).map(
        (variant) => `text-${variant}`,
      ),
      z: typedObjectKeys(tailwindTheme.zIndex).map((variant) => `z-${variant}`),
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatPrice(price: number) {
  return price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function formatPeriodUnitLabel(planId: PlanId) {
  return planId === "Basic-USD-Monthly" ? "mo" : "yr";
}

function getDaySuffix(day: number) {
  if (day >= 11 && day <= 13) return "th";

  const suffixes: { [key: number]: string } = { 1: "st", 2: "nd", 3: "rd" };
  return suffixes[day % 10] || "th";
}

export function formatShortDate(unixMilliseconds: number) {
  const date = new Date(unixMilliseconds);
  const day = date.getDate();
  const suffix = getDaySuffix(day);

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  return formattedDate.replace(day.toString(), `${day}${suffix}`);
}
