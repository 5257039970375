import React from "react";
import {
  ArrowLeft,
  DotsThreeVertical,
  LinkedinLogo,
} from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
// import LeadPreview from "common/components/LeadPreview";

import { getGroupParticipantsString } from "../../../utils";
import { Conversation } from "../../../types";
import ParticipantsProfiles from "./ParticipantsProfiles";
import ParticipantInfo from "../../ParticipantInfo";
// import NotesDialog from "../../../../leads/components/AllLeads/NotesDialog";
import ConversationInfo from "./ConversationInfo";
import HeaderLoader from "./HeaderLoader";
import HeaderTags from "./HeaderTags";

interface InboxHeaderProps {
  conversation: Conversation;
  isLoading: boolean;
  resetActiveConversation: () => void;
}

export default function InboxHeader({
  conversation,
  isLoading,
  resetActiveConversation,
}: InboxHeaderProps) {
  // const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  // const [isNotesOpen, setIsNotesOpen] = useState(false);

  const isDesktop = useTwBreakpoint("lg");
  const groupParticipantsString = conversation
    ? getGroupParticipantsString(conversation)
    : null;

  function getContent() {
    if (isLoading) {
      return <HeaderLoader />;
    }

    const { participants } = conversation;
    const {
      public_identifier: publicIdentifier,
      full_name: fullName,
      // is_lead: isLead,
    } = participants[0];

    const isGroupConversation = participants.length > 1;

    const profileActionsDropdown = (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button intent="iconOnly" variant="secondary-black">
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          {/* TODO: Temporarily Disable until Inbox Node is Released */}
          {/* <RenderIf condition={isLead}>
            <DropdownMenuItem onClick={() => setIsNotesOpen(true)}>
              <NoteBlank /> View Notes
            </DropdownMenuItem>
          </RenderIf> */}

          {/* <DropdownMenuItem
            className="lg:hidden"
            onClick={() => setIsPreviewOpen(true)}
          >
            <User /> Profile Preview
          </DropdownMenuItem> */}

          <DropdownMenuItem asChild>
            <a
              href={`https://linkedin.com/in/${publicIdentifier}`}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinLogo weight="fill" /> LinkedIn
            </a>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );

    return (
      <div className="flex w-full flex-col gap-3 rounded-2xl bg-white p-4 shadow-sm md:shadow-transparent">
        <div className="flex w-full flex-row items-center justify-between">
          {/* Back to conversation button */}
          <Button
            className="lg:hidden"
            variant="quaternary-black"
            intent="iconOnly"
            onClick={resetActiveConversation}
          >
            <ArrowLeft />
          </Button>

          {/* Conversation Info */}
          {isDesktop ? (
            <ParticipantInfo
              showOrigins
              profileSize="md"
              conversation={conversation}
            />
          ) : (
            <ConversationInfo
              conversation={conversation}
              groupParticipantsString={groupParticipantsString}
              fullName={fullName}
            />
          )}

          {/* Conversation actions */}
          <div className="flex items-center gap-2">
            <RenderIf condition={isDesktop || !!groupParticipantsString}>
              <ParticipantsProfiles
                conversation={conversation}
                // openLeadsPreview={() => setIsPreviewOpen(true)}
              />
            </RenderIf>

            <RenderIf condition={!groupParticipantsString}>
              {profileActionsDropdown}
            </RenderIf>
          </div>
        </div>

        {/* Conversation tags management */}
        <RenderIf condition={isDesktop && !isGroupConversation}>
          <HeaderTags conversation={conversation} />
        </RenderIf>

        {/* Sheet / Sidebar for lead preview */}
        {/* <LeadPreview
          lead={participants[0]}
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
        /> */}

        {/* Modal for displaying add notes modal */}
        {/* <NotesDialog
          lead={participants[0]}
          isOpen={isNotesOpen}
          onClose={() => setIsNotesOpen(false)}
        /> */}
      </div>
    );
  }

  return (
    <section className="relative z-10 flex w-full flex-col bg-black-50 p-2 lg:absolute">
      {getContent()}
    </section>
  );
}
